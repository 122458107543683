import React from 'react';
import { useIntl } from 'react-intl';
import classes from './StationItem.module.scss';
import AddressIcon from '@/icons/address.svg';
import CheckIcon from '@/icons/check.svg';
const StationItem = ({ station, selectedStation, onClick }) => {
    var _a;
    const intl = useIntl();
    return (React.createElement("div", { onClick: onClick, className: `${classes.item} ${(station === null || station === void 0 ? void 0 : station.id) === selectedStation ? classes.active : ''}` },
        React.createElement("div", { className: classes.itemIcon }, (station === null || station === void 0 ? void 0 : station.id) === selectedStation ? React.createElement(CheckIcon, { width: 36, height: 36 }) : React.createElement(AddressIcon, null)),
        React.createElement("div", { className: classes.itemBox },
            React.createElement("div", { className: classes.itemName }, station === null || station === void 0 ? void 0 : station.name),
            React.createElement("div", { className: classes.waitingDrivers },
                intl.formatMessage({ id: 'officeTrips.waitingDrivers', defaultMessage: 'Очікуючих водіїв' }),
                ` - ${(_a = station === null || station === void 0 ? void 0 : station.driversCount) !== null && _a !== void 0 ? _a : '—'}`),
            React.createElement("div", { className: classes.address }, station === null || station === void 0 ? void 0 : station.address))));
};
export default StationItem;
