import React from 'react';
import CheckIcon from '@/icons/check.svg';
import TimeIcon from '@/icons/time.svg';
export const getCarTransferAcceptanceStatus = (statusName, intl) => {
    var _a;
    return ((_a = {
        WAITING_ACCEPT: intl.formatMessage({ id: 'acts.waitingAnswers', defaultMessage: 'Очікує відповіді' }),
        ACCEPTED: intl.formatMessage({ id: 'officeTrips.accepted', defaultMessage: 'Підтверджено' }),
        CANCELLED: intl.formatMessage({ id: 'officeTrips.canceled', defaultMessage: 'Відмінено' }),
        REJECTED: intl.formatMessage({ id: 'officeTrips.rejected', defaultMessage: 'Відхилено' })
    }[statusName]) !== null && _a !== void 0 ? _a : '—');
};
export const getCarTransferAcceptanceBg = (status) => {
    var _a;
    return ((_a = {
        WAITING_ACCEPT: '#F7F7F7',
        CANCELLED: '#F7F7F7',
        REJECTED: '#F7F7F7',
        ACCEPTED: '#EBF8F4'
    }[status !== null && status !== void 0 ? status : '']) !== null && _a !== void 0 ? _a : '#EBF8F4');
};
export const getCarTransferAcceptanceColor = (status) => {
    var _a;
    return ((_a = {
        WAITING_ACCEPT: '#A7A7A7',
        CANCELLED: '#E53A3A',
        REJECTED: '#A7A7A7',
        ACCEPTED: '#30B78E'
    }[status !== null && status !== void 0 ? status : '']) !== null && _a !== void 0 ? _a : '#A7A7A7');
};
export const getCarTransferAcceptanceIcon = (width = 16, height = 16, color) => ({
    WAITING_ACCEPT: React.createElement(TimeIcon, { style: { fill: color }, width: width, height: height }),
    CANCELLED: React.createElement(TimeIcon, { style: { fill: color }, width: width, height: height }),
    REJECTED: React.createElement(TimeIcon, { style: { fill: color }, width: width, height: height }),
    ACCEPTED: React.createElement(CheckIcon, { style: { fill: color }, width: width, height: height })
});
export const getTechnicalPassportStatus = (type, intl) => {
    const typeMessages = {
        MISS: 'common.miss',
        PRESENT: 'common.present'
    };
    const defaultMessage = '—';
    const messageId = typeMessages[type] || defaultMessage;
    return intl.formatMessage({ id: messageId, defaultMessage });
};
export const getTechnicalLiquidsStatus = (type, intl) => {
    const typeMessages = {
        IN_NORM: 'common.inNorm',
        REFILL_REQUIRED: 'common.refill'
    };
    const defaultMessage = '—';
    const messageId = typeMessages[type] || defaultMessage;
    return intl.formatMessage({ id: messageId, defaultMessage });
};
// export const getCarTransferAcceptanceType = (statusName: string, intl: IntlFormatters): string | null => ({
//   PARK_TO_DRIVER: intl.formatMessage({ id: 'acts.waitingAnswers', defaultMessage: 'Очікує відповіді' }),
//   DRIVER_TO_PARK: intl.formatMessage({ id: 'officeTrips.accepted', defaultMessage: 'Підтверджено' }),
//   DRIVER_TO_DRIVER: intl.formatMessage({ id: 'officeTrips.canceled', defaultMessage: 'Відмінено' })
// }[statusName] ?? '—')
