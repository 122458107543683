import { gql } from '@apollo/client';
export const GET_CAR_TRANSFER_ACCEPTANCE_INFO = gql `
  query GetCarTransferAcceptanceInfo {
    getCarTransferAcceptanceInfo {
      lastRejectedCarTransferAcceptance {
        id
        humanId
        status
        createdAt
      }
      activeCarTransferAcceptance {
        id
        humanId
        status
        createdAt
        trackerOdometer
        type
        sourceDriverId
        sourceUserId
        targetDriverId
        targetUserId
        car {
          id
          licensePlate
          model
          photoUrl
        }
        sourceDriver {
          firstName
          lastName
        }
        targetDriver {
          firstName
          lastName
        }
        sourceUser {
          firstName
          lastName
        }
        targetUser {
          firstName
          lastName
        }
      }
    }
  }
`;
export const GET_CAR_TRANSFER_ACCEPTANCE = gql `
  query GetCarTransferAcceptance($transferAcceptanceId: Uuid!) {
    getCarTransferAcceptance(transferAcceptanceId: $transferAcceptanceId) {
      humanId
      odometer
      oilLevel
      comment
      coolingLiquidLevel
      createdAt
      defects
      type
      status
      technicalPassport
      trackerOdometer
      rejectionComment
      car {
        licensePlate
        model
      }
      defectsPhotos {
        original
        thumbnail
      }
      photos {
        bodyFront {
          original
          thumbnail
        }
        bodyBack {
          original
          thumbnail
        }
        bodyLeft {
          original
          thumbnail
        }
        bodyRight {
          original
          thumbnail
        }
        cabinBack {
          original
          thumbnail
        }
        cabinFront {
          original
          thumbnail
        }
        dashboard {
          original
          thumbnail
        }
        underHood {
          original
          thumbnail
        }
        trunc {
          original
          thumbnail
        }
      }
      sourceDriver {
        id
        firstName
        lastName
        avatarUrl
      }
      targetDriver {
        id
        firstName
        lastName
        avatarUrl
      }
      sourceUser {
        id
        firstName
        lastName
        avatarUrl
      }
      targetUser {
        id
        firstName
        lastName
        avatarUrl
      }
    }
  }
`;
export const ACCEPT_CAR_TRANSFER_ACCEPTANCE = gql `
  mutation AcceptCarTransferAcceptance($transferAcceptanceId: Uuid!) {
    acceptCarTransferAcceptance(transferAcceptanceId: $transferAcceptanceId) {
      success
    }
  }
`;
export const REJECT_CAR_TRANSFER_ACCEPTANCE = gql `
  mutation RejectCarTransferAcceptance($rejectCarTransferAcceptanceInput: RejectCarTransferAcceptanceInput!) {
    rejectCarTransferAcceptance(rejectCarTransferAcceptanceInput: $rejectCarTransferAcceptanceInput) {
      success
    }
  }
`;
export const CREATE_CAR_TRANSFER_ACCEPTANCE = gql `
  mutation CreateCarTransferAcceptance($createCarTransferAcceptanceInput: CreateCarTransferAcceptanceInput!) {
    createCarTransferAcceptance(createCarTransferAcceptanceInput: $createCarTransferAcceptanceInput) {
      success
    }
  }
`;
export const CANCEL_CAR_TRANSFER_ACCEPTANCE = gql `
  mutation CancelCarTransferAcceptance($transferAcceptanceId: Uuid!) {
    cancelCarTransferAcceptance(transferAcceptanceId: $transferAcceptanceId) {
      success
    }
  }
`;
export const LIST_SATISFYING_DRIVERS_FOR_CAR_TRANSFER_ACCEPTANCE = gql `
  query ListSatisfyingDriversForCarTransferAcceptance {
    listSatisfyingDriversForCarTransferAcceptance {
      drivers {
        id
        firstName
        lastName
        avatarUrl
      }
    }
  }
`;
