import React from 'react';
import classes from './IconForSchedule.module.scss';
import AutoPoundIcon from '@/icons/auto-pound.svg';
import CrewDriversIcon from '@/icons/crew-drivers.svg';
import DayOffIcon from '@/icons/dayoff.svg';
import OtherIcon from '@/icons/other.svg';
import RentalIcon from '@/icons/rent.svg';
import RoadAccidentIcon from '@/icons/road-accident.svg';
import ServiceStationIcon from '@/icons/service-station.svg';
import SickIcon from '@/icons/sick.svg';
import SingleDriverIcon from '@/icons/single-driver.svg';
import VehicleInspectionIcon from '@/icons/vehicle-inspection.svg';
const createEventIcons = (width = 24, height = 24) => ({
    BUSY_WITH_PRIVATE_TRADER: React.createElement(SingleDriverIcon, { width: width, height: height }),
    BUSY_WITH_CREW: React.createElement(CrewDriversIcon, { width: width, height: height }),
    ROAD_ACCIDENT: React.createElement(RoadAccidentIcon, { width: width, height: height }),
    ON_SERVICE_STATION: React.createElement(ServiceStationIcon, { width: width, height: height }),
    VEHICLE_INSPECTION: React.createElement(VehicleInspectionIcon, { width: width, height: height }),
    AUTO_POUND: React.createElement(AutoPoundIcon, { width: width, height: height }),
    OTHER: React.createElement(OtherIcon, { width: width, height: height }),
    RENTAL: React.createElement(RentalIcon, { width: width, height: height }),
    DAYOFF: React.createElement(DayOffIcon, { width: width, height: height }),
    DRIVER_SICK_DAY: React.createElement(SickIcon, { width: width, height: height })
});
export const getEventIcon = (eventType, width, height) => { var _a; return ((_a = createEventIcons(width, height)[eventType]) !== null && _a !== void 0 ? _a : null); };
const IconForSchedule = ({ eventType, width, height }) => {
    return (React.createElement("div", { className: classes.box }, getEventIcon(eventType, width, height)));
};
export default IconForSchedule;
