import { useCallback, useEffect, useState } from 'react';
const usePageVisibility = () => {
    const [visibilityTab, setVisibilityTab] = useState(true);
    const handleVisibilityChange = useCallback(() => {
        if (document.visibilityState === 'hidden') {
            setVisibilityTab(false);
        }
        else if (document.visibilityState === 'visible') {
            setVisibilityTab(true);
        }
    }, []);
    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [handleVisibilityChange]);
    return visibilityTab;
};
export default usePageVisibility;
