import React, { useEffect } from 'react';
import ProtectedRoutes from '../AllRoutes/ProtectedRoutes';
import PublicRoutes from '../AllRoutes/PublicRoutes';
import Preloader from '@/atoms/Preloader/Preloader';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
const EntryPoint = () => {
    const { checkAuth } = useAppDispatch();
    const { companyId, user, pending, error } = useAppSelector((state) => state.auth);
    const isLogin = companyId && (user === null || user === void 0 ? void 0 : user.id);
    const isLoginAndBlocked = error === 'BLOCKED_AUTHORIZED';
    useEffect(() => {
        checkAuth();
    }, []);
    if (pending) {
        return React.createElement(Preloader, null);
    }
    return React.createElement(React.Fragment, null, isLogin ? React.createElement(ProtectedRoutes, null) : React.createElement(PublicRoutes, { isLoginAndBlocked: isLoginAndBlocked }));
};
export default EntryPoint;
