var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import { Modal, message } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import classes from './CancelAct.module.scss';
import BtnBlue from '@/atoms/BtnBlue/BtnBlue';
import BtnTransparent from '@/atoms/BtnTransparent/BtnTransparent';
import globalClasses from '@/containers/App/Global.module.scss';
import { CANCEL_CAR_TRANSFER_ACCEPTANCE } from '@/graphql/acts';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import { routes } from '@/utils/constants';
import { getErrorMessage } from '@/utils/validateMessages';
const CancelAct = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const { setActsPopup } = useAppDispatch();
    const { visibility, defaultValues } = useAppSelector((state) => state.acts.modals.cancelAct);
    const transferAcceptanceId = defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.id;
    const humanId = defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.humanId;
    const [cancelCarTransferAcceptance] = useMutation(CANCEL_CAR_TRANSFER_ACCEPTANCE);
    const onFinish = () => __awaiter(void 0, void 0, void 0, function* () {
        cancelCarTransferAcceptance({
            variables: {
                transferAcceptanceId
            }
        }).then(() => {
            void message.success(intl.formatMessage({ id: 'common.canceledAct', defaultMessage: 'Акт скасовано' }), 3);
            navigate(routes.cabinet.f);
        }, (err) => { getErrorMessage(err, intl); });
    });
    return (React.createElement(Modal, { open: visibility, onCancel: () => setActsPopup('cancelAct', false), footer: false },
        React.createElement("div", { className: classes.title },
            intl.formatMessage({ id: 'acts.areYouSureCancelAct', defaultMessage: 'Ви впевнені, що ви хочете скасувати акт' }),
            " ",
            React.createElement("span", null,
                "\u2116 ",
                humanId),
            " ?"),
        React.createElement("div", { className: globalClasses.controlsFixed },
            React.createElement(BtnTransparent, { type: 'button', onClick: () => setActsPopup('cancelAct', false), text: intl.formatMessage({ id: 'common.cancel', defaultMessage: 'Скасувати' }) }),
            React.createElement(BtnBlue, { onClick: onFinish, text: intl.formatMessage({ id: 'common.cancelAct', defaultMessage: 'Скасувати акт' }) }))));
};
export default CancelAct;
