import { useMutation, useQuery } from '@apollo/client';
import { Alert, message } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import classes from './OfficeTrips.module.scss';
import BtnBlue from '@/atoms/BtnBlue/BtnBlue';
import BtnTransparent from '@/atoms/BtnTransparent/BtnTransparent';
import Preloader from '@/atoms/Preloader/Preloader';
import { getOfficeTripsDriverStatus } from '@/components/OfficeTrips/helpers/helpers';
import StationItem from '@/components/OfficeTrips/StationSelection/StationItem/StationItem';
import { GET_DRIVER_STATUS, LIST_TRIP_STATIONS, SET_STATUS_OFFLINE } from '@/graphql/officeTrips';
import StatusIcon from '@/icons/status.svg';
import { routes } from '@/utils/constants';
import { getErrorMessage } from '@/utils/validateMessages';
const OfficeTrips = () => {
    var _a, _b, _c;
    const intl = useIntl();
    const { loading: loadingListStations, error: errorListStations, data: listStations } = useQuery(LIST_TRIP_STATIONS, {
        variables: {
            listTripStationsInput: {
                offset: 0,
                limit: 50
            }
        }
    });
    const { loading, error, data } = useQuery(GET_DRIVER_STATUS);
    const [setStatusOffline] = useMutation(SET_STATUS_OFFLINE, {
        refetchQueries: () => [GET_DRIVER_STATUS, LIST_TRIP_STATIONS]
    });
    const setStatusOfflineForDriver = () => {
        setStatusOffline().then(() => {
            void message.success(intl.formatMessage({ id: 'common.statusChanged', defaultMessage: 'Статус змінено' }), 3);
        }, (err) => { getErrorMessage(err, intl); });
    };
    if (loading)
        return React.createElement(Preloader, { inner: true });
    const driverStatus = data === null || data === void 0 ? void 0 : data.getDriverStatus;
    const getStationName = (_b = (_a = listStations === null || listStations === void 0 ? void 0 : listStations.listTripStations) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b.find((station) => station.id === (driverStatus === null || driverStatus === void 0 ? void 0 : driverStatus.stationId));
    return (React.createElement("div", { className: classes.box },
        React.createElement("div", { className: classes.boxHead },
            React.createElement("div", { className: classes.boxTitle }, "Office Trips"),
            React.createElement(Link, { to: routes.officeTrips.f, className: classes.boxLink }, intl.formatMessage({ id: 'common.moreLink', defaultMessage: 'Більше' }))),
        error && React.createElement(Alert, { message: intl.formatMessage({ id: 'error.dataLoading', defaultMessage: 'Ошибка при загрузке данных' }), type: "error" }),
        !error && (React.createElement("div", { className: classes.holder },
            React.createElement("div", { className: classes.statusBox },
                React.createElement("div", { className: `${classes.status} ${(driverStatus === null || driverStatus === void 0 ? void 0 : driverStatus.status) !== 'OFFLINE' ? classes.statusActive : ''}` },
                    React.createElement(StatusIcon, null),
                    getOfficeTripsDriverStatus((_c = driverStatus === null || driverStatus === void 0 ? void 0 : driverStatus.status) !== null && _c !== void 0 ? _c : '', intl)),
                (driverStatus === null || driverStatus === void 0 ? void 0 : driverStatus.status) === 'WAITING_ORDER' && React.createElement(BtnTransparent, { onClick: setStatusOfflineForDriver, text: intl.formatMessage({ id: 'common.offline', defaultMessage: 'Офлайн' }) })),
            (driverStatus === null || driverStatus === void 0 ? void 0 : driverStatus.status) === 'OFFLINE' && (React.createElement("div", { className: classes.description }, intl.formatMessage({ id: 'officeTrips.waitingForOrderDescription', defaultMessage: 'Натисніть кнопку “Чекаю замовлення”, щоб мати можливість отримати поїздку' }))),
            (driverStatus === null || driverStatus === void 0 ? void 0 : driverStatus.status) === 'OFFLINE' && (React.createElement(Link, { state: { toggleStations: true }, to: routes.officeTrips.f, className: classes.link },
                React.createElement(BtnBlue, { fullWidth: true, high: true, text: intl.formatMessage({ id: 'officeTrips.waitingForOrder', defaultMessage: 'Чекаю замовлення' }) }))),
            (driverStatus === null || driverStatus === void 0 ? void 0 : driverStatus.status) === 'WAITING_ORDER' && (React.createElement(React.Fragment, null,
                errorListStations && React.createElement(Alert, { message: intl.formatMessage({ id: 'error.dataLoading', defaultMessage: 'Ошибка при загрузке данных' }), type: "error" }),
                loadingListStations && React.createElement(Preloader, { inner: true }),
                React.createElement(StationItem, { station: {
                        id: driverStatus === null || driverStatus === void 0 ? void 0 : driverStatus.stationId,
                        name: getStationName ? getStationName === null || getStationName === void 0 ? void 0 : getStationName.name : driverStatus === null || driverStatus === void 0 ? void 0 : driverStatus.stationId,
                        driversCount: getStationName ? getStationName === null || getStationName === void 0 ? void 0 : getStationName.driversCount : '—',
                        address: getStationName ? getStationName === null || getStationName === void 0 ? void 0 : getStationName.address : '—'
                    } })))))));
};
export default OfficeTrips;
