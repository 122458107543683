export default {
    ad: 'Андорра',
    ae: 'Об\'єднані Арабські Емірати',
    af: 'Афганістан',
    ag: 'Антигуа і Барбуда',
    ai: 'Ангілья',
    al: 'Албанія',
    am: 'Вірменія',
    ao: 'Ангола',
    aq: 'Антарктида',
    ar: 'Аргентина',
    as: 'Американське Самоа',
    at: 'Австрія',
    au: 'Австралія',
    aw: 'Аруба',
    ax: 'Аландські острови',
    az: 'Азербайджан',
    ba: 'Боснія і Герцеговина',
    bb: 'Барбадос',
    bd: 'Бангладеш',
    be: 'Бельгія',
    bf: 'Буркіна-Фасо',
    bg: 'Болгарія',
    bh: 'Бахрейн',
    bi: 'Бурунді',
    bj: 'Бенін',
    bl: 'Сен-Бартельмі',
    bm: 'Бермуди',
    bn: 'Бруней',
    bo: 'Болівія',
    bq: 'Карибські Нідерланди',
    br: 'Бразилія',
    bs: 'Багами',
    bt: 'Бутан',
    bv: 'Острів Буве',
    bw: 'Ботсвана',
    by: 'Білорусь',
    bz: 'Беліз',
    ca: 'Канада',
    cc: 'Кокосові острови',
    cd: 'Конго - Кіншаса',
    cf: 'Центральноафриканська Республіка',
    cg: 'Конго - Браззавіль',
    ch: 'Швейцарія',
    ci: 'Кот-д\'Івуар',
    ck: 'Острови Кука',
    cl: 'Чилі',
    cm: 'Камерун',
    cn: 'Китай',
    co: 'Колумбія',
    cr: 'Коста-Рика',
    cu: 'Куба',
    cv: 'Кабо-Верде',
    cw: 'Кюрасао',
    cx: 'Острів Різдва',
    cy: 'Кіпр',
    cz: 'Чехія',
    de: 'Німеччина',
    dj: 'Джибуті',
    dk: 'Данія',
    dm: 'Домініка',
    do: 'Домініканська Республіка',
    dz: 'Алжир',
    ec: 'Еквадор',
    ee: 'Естонія',
    eg: 'Єгипет',
    eh: 'Західна Сахара',
    er: 'Еритрея',
    es: 'Іспанія',
    et: 'Ефіопія',
    fi: 'Фінляндія',
    fj: 'Фіджі',
    fk: 'Фолклендські острови',
    fm: 'Мікронезія',
    fo: 'Фарерські острови',
    fr: 'Франція',
    ga: 'Габон',
    gb: 'Велика Британія',
    gd: 'Гренада',
    ge: 'Грузія',
    gf: 'Французька Гвіана',
    gg: 'Гернсі',
    gh: 'Гана',
    gi: 'Гібралтар',
    gl: 'Гренландія',
    gm: 'Гамбія',
    gn: 'Гвінея',
    gp: 'Гваделупа',
    gq: 'Екваторіальна Гвінея',
    gr: 'Греція',
    gs: 'Південна Джорджія та Південні Сандвічеві острови',
    gt: 'Гватемала',
    gu: 'Гуам',
    gw: 'Гвінея-Бісау',
    gy: 'Гайана',
    hk: 'Гонконг (САР Китаю)',
    hm: 'Острови Херд і Макдональд',
    hn: 'Гондурас',
    hr: 'Хорватія',
    ht: 'Гаїті',
    hu: 'Угорщина',
    id: 'Індонезія',
    ie: 'Ірландія',
    il: 'Ізраїль',
    im: 'Острів Мен',
    in: 'Індія',
    io: 'Британська територія в Індійському океані',
    iq: 'Ірак',
    ir: 'Іран',
    is: 'Ісландія',
    it: 'Італія',
    je: 'Джерсі',
    jm: 'Ямайка',
    jo: 'Йорданія',
    jp: 'Японія',
    ke: 'Кенія',
    kg: 'Киргизстан',
    kh: 'Камбоджа',
    ki: 'Кірибаті',
    km: 'Коморські острови',
    kn: 'Сент-Кітс і Невіс',
    kp: 'Північна Корея',
    kr: 'Південна Корея',
    kw: 'Кувейт',
    ky: 'Кайманові острови',
    kz: 'Казахстан',
    la: 'Лаос',
    lb: 'Ліван',
    lc: 'Сент-Люсія',
    li: 'Ліхтенштейн',
    lk: 'Шрі-Ланка',
    lr: 'Ліберія',
    ls: 'Лесото',
    lt: 'Литва',
    lu: 'Люксембург',
    lv: 'Латвія',
    ly: 'Лівія',
    ma: 'Марокко',
    mc: 'Монако',
    md: 'Молдова',
    me: 'Чорногорія',
    mf: 'Сен-Мартен',
    mg: 'Мадагаскар',
    mh: 'Маршаллові острови',
    mk: 'Північна Македонія',
    ml: 'Малі',
    mm: 'М\'янма (Бірма)',
    mn: 'Монголія',
    mo: 'Макао (САР Китаю)',
    mp: 'Північні Маріанські острови',
    mq: 'Мартиніка',
    mr: 'Мавританія',
    ms: 'Монтсеррат',
    mt: 'Мальта',
    mu: 'Маврикій',
    mv: 'Мальдіви',
    mw: 'Малаві',
    mx: 'Мексика',
    my: 'Малайзія',
    mz: 'Мозамбік',
    na: 'Намібія',
    nc: 'Нова Каледонія',
    ne: 'Нігер',
    nf: 'Острів Норфолк',
    ng: 'Нігерія',
    ni: 'Нікарагуа',
    nl: 'Нідерланди',
    no: 'Норвегія',
    np: 'Непал',
    nr: 'Науру',
    nu: 'Ніуе',
    nz: 'Нова Зеландія',
    om: 'Оман',
    pa: 'Панама',
    pe: 'Перу',
    pf: 'Французька Полінезія',
    pg: 'Папуа-Нова Гвінея',
    ph: 'Філіппіни',
    pk: 'Пакистан',
    pl: 'Польща',
    pm: 'Сен-П\'єр і Мікелон',
    pn: 'Острови Піткерн',
    pr: 'Пуерто-Рико',
    ps: 'Палестинські території',
    pt: 'Португалія',
    pw: 'Палау',
    py: 'Парагвай',
    qa: 'Катар',
    re: 'Реюньйон',
    ro: 'Румунія',
    rs: 'Сербія',
    ru: 'Росія',
    rw: 'Руанда',
    sa: 'Саудівська Аравія',
    sb: 'Соломонові острови',
    sc: 'Сейшельські острови',
    sd: 'Судан',
    se: 'Швеція',
    sg: 'Сінгапур',
    sh: 'Острів Святої Єлени',
    si: 'Словенія',
    sj: 'Шпіцберген і Ян-Маєн',
    sk: 'Словаччина',
    sl: 'Сьєрра-Леоне',
    sm: 'Сан-Марино',
    sn: 'Сенегал',
    so: 'Сомалі',
    sr: 'Суринам',
    ss: 'Південний Судан',
    st: 'Сан-Томе і Прінсіпі',
    sv: 'Сальвадор',
    sx: 'Сінт-Мартен',
    sy: 'Сирія',
    sz: 'Есватіні',
    tc: 'Острови Теркс і Кайкос',
    td: 'Чад',
    tf: 'Французькі Південні та Антарктичні Території',
    tg: 'Того',
    th: 'Таїланд',
    tj: 'Таджикистан',
    tk: 'Токелау',
    tl: 'Тімор-Лешті',
    tm: 'Туркменістан',
    tn: 'Туніс',
    to: 'Тонга',
    tr: 'Туреччина',
    tt: 'Тринідад і Тобаго',
    tv: 'Тувалу',
    tw: 'Тайвань',
    tz: 'Танзанія',
    ua: 'Україна',
    ug: 'Уганда',
    um: 'Зовнішні малі острови США',
    us: 'Сполучені Штати Америки',
    uy: 'Уругвай',
    uz: 'Узбекистан',
    va: 'Ватикан',
    vc: 'Сент-Вінсент і Гренадини',
    ve: 'Венесуела',
    vg: 'Британські Віргінські острови',
    vi: 'Американські Віргінські острови',
    vn: 'В\'єтнам',
    vu: 'Вануату',
    wf: 'Волліс і Футуна',
    ws: 'Самоа',
    ye: 'Ємен',
    yt: 'Майотта',
    za: 'Південно-Африканська Республіка',
    zm: 'Замбія',
    zw: 'Зімбабве'
};
