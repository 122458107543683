var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GET_USER } from '@/graphql/auth';
import client from '@/graphql/client';
import { getLocale } from '@/utils/helpers';
import { getAccessToken, setAccessToken } from '@/utils/localStorage';
const { language } = navigator;
const languageAbbreviation = language === null || language === void 0 ? void 0 : language.slice(0, 2).toUpperCase();
export const LOCALE_DEFAULT = getLocale(languageAbbreviation);
export const initialState = {
    companyId: '',
    pending: true,
    error: null,
    user: {
        id: '',
        firstName: '',
        lastName: '',
        phone: '',
        avatarUrl: '',
        lang: LOCALE_DEFAULT
    },
    settings: {
        officeTripsEnabled: false
    }
};
function createExtraActions() {
    function authLogoutRequest() {
        return createAsyncThunk('auth/authLogoutRequest', (args_1, _a) => __awaiter(this, [args_1, _a], void 0, function* (args, { dispatch }) {
            var _b, _c, _d;
            try {
                dispatch(authSlice.actions.authSetPending(true));
                setAccessToken(null);
                yield client.resetStore();
                dispatch(authSlice.actions.authLogout());
                window.location.assign('/');
            }
            catch (error) {
                const errorCode = (_d = (_c = (_b = error === null || error === void 0 ? void 0 : error.graphQLErrors) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.extensions) === null || _d === void 0 ? void 0 : _d.code;
                dispatch(authSlice.actions.authSetError(errorCode));
            }
            finally {
                window.location.assign('/');
            }
        }));
    }
    function checkAuth() {
        return createAsyncThunk('auth/checkAuth', (args_1, _a) => __awaiter(this, [args_1, _a], void 0, function* (args, { dispatch, getState }) {
            var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
            const token = getAccessToken();
            if (!token) {
                dispatch(AuthActionCreators.authSetPending(false));
                return;
            }
            try {
                const state = getState();
                let { companyId, user, settings } = state === null || state === void 0 ? void 0 : state.auth;
                const { data: { me } } = yield client.query({ query: GET_USER, fetchPolicy: 'network-only' });
                companyId = (_c = (_b = me === null || me === void 0 ? void 0 : me.user) === null || _b === void 0 ? void 0 : _b.companyId) !== null && _c !== void 0 ? _c : '';
                user = {
                    id: (_d = me === null || me === void 0 ? void 0 : me.user) === null || _d === void 0 ? void 0 : _d.id,
                    phone: (_e = me === null || me === void 0 ? void 0 : me.user) === null || _e === void 0 ? void 0 : _e.phone,
                    firstName: (_f = me === null || me === void 0 ? void 0 : me.user) === null || _f === void 0 ? void 0 : _f.firstName,
                    lastName: (_g = me === null || me === void 0 ? void 0 : me.user) === null || _g === void 0 ? void 0 : _g.lastName,
                    lang: (_h = me === null || me === void 0 ? void 0 : me.user) === null || _h === void 0 ? void 0 : _h.lang,
                    avatarUrl: (_j = me === null || me === void 0 ? void 0 : me.user) === null || _j === void 0 ? void 0 : _j.avatarUrl
                };
                settings = {
                    officeTripsEnabled: (_k = me === null || me === void 0 ? void 0 : me.settings) === null || _k === void 0 ? void 0 : _k.officeTripsEnabled
                };
                dispatch(AuthActionCreators.authSetCompanyId(companyId));
                dispatch(AuthActionCreators.authLoginSuccess(user));
                dispatch(AuthActionCreators.authSetSettings(settings));
            }
            catch (error) {
                const errorCode = (_o = (_m = (_l = error === null || error === void 0 ? void 0 : error.graphQLErrors) === null || _l === void 0 ? void 0 : _l[0]) === null || _m === void 0 ? void 0 : _m.extensions) === null || _o === void 0 ? void 0 : _o.code;
                if (errorCode === 'UNAUTHORIZED') {
                    setAccessToken(null);
                }
                if (errorCode === 'BLOCKED') {
                    setAccessToken(null);
                    dispatch(authSlice.actions.authSetError('BLOCKED_AUTHORIZED'));
                }
            }
            finally {
                dispatch(AuthActionCreators.authSetPending(false));
            }
        }));
    }
    function authGetUser() {
        return createAsyncThunk('auth/authGetUser', (args_1, _a) => __awaiter(this, [args_1, _a], void 0, function* (args, { dispatch }) {
            var _b, _c, _d, _e;
            try {
                const { data: { me } } = yield client.query({ query: GET_USER, fetchPolicy: 'network-only' });
                const { companyId, id, phone, firstName, lastName, lang, avatarUrl } = me === null || me === void 0 ? void 0 : me.user;
                const user = {
                    id, phone, firstName, lastName, lang, avatarUrl
                };
                const settings = {
                    officeTripsEnabled: (_b = me === null || me === void 0 ? void 0 : me.settings) === null || _b === void 0 ? void 0 : _b.officeTripsEnabled
                };
                dispatch(authSlice.actions.authSetCompanyId(companyId !== null && companyId !== void 0 ? companyId : ''));
                dispatch(authSlice.actions.authLoginSuccess(user));
                dispatch(AuthActionCreators.authSetSettings(settings));
                dispatch(authSlice.actions.authSetError(''));
            }
            catch (error) {
                const errorCode = (_e = (_d = (_c = error === null || error === void 0 ? void 0 : error.graphQLErrors) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.extensions) === null || _e === void 0 ? void 0 : _e.code;
                dispatch(authSlice.actions.authSetError(errorCode));
                if (errorCode === 'BLOCKED') {
                    setAccessToken(null);
                }
            }
            finally {
                dispatch(authSlice.actions.authSetPending(false));
            }
        }));
    }
    return {
        authGetUser: authGetUser(),
        checkAuth: checkAuth(),
        authLogoutRequest: authLogoutRequest()
    };
}
export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authSetPending: (state, action) => {
            state.pending = action.payload;
        },
        authSetError: (state, action) => {
            state.error = action.payload;
        },
        authSetCompanyId: (state, action) => {
            state.companyId = action.payload;
        },
        authLoginSuccess: (state, action) => {
            state.user = action.payload;
        },
        authSetSettings: (state, action) => {
            state.settings = action.payload;
        },
        changeLang: (state, action) => {
            state.user.lang = action.payload;
        },
        authLogout: () => initialState
    }
});
const { authLogout, authSetPending, authSetCompanyId, authLoginSuccess, authSetSettings, changeLang } = authSlice.actions;
const extraActions = createExtraActions();
export const AuthActionCreators = Object.assign({ authLogout,
    authSetPending,
    authSetCompanyId,
    authLoginSuccess,
    authSetSettings,
    changeLang }, extraActions);
export default authSlice.reducer;
