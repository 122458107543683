export default {
    selectedCountryAriaLabel: 'Выбранная страна',
    noCountrySelected: 'Страна не выбрана',
    countryListAriaLabel: 'Список стран',
    searchPlaceholder: 'Поиск',
    zeroSearchResults: 'результатов не найдено',
    oneSearchResult: 'найден 1 результат',
    ac: 'Остров Вознесения',
    xk: 'Косово'
};
