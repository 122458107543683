import { useMutation, useQuery } from '@apollo/client';
import { Alert, Image, message } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import classes from './CarTransferProfile.module.scss';
import { getCarTransferAcceptanceBg, getCarTransferAcceptanceColor, getCarTransferAcceptanceIcon, getCarTransferAcceptanceStatus, getTechnicalLiquidsStatus, getTechnicalPassportStatus } from '../helpers/helpers';
import CancelAct from '../Modals/CancelAct/CancelAct';
import RejectAct from '../Modals/RejectAct/RejectAct';
import Breadcrumb from '@/atoms/Breadcrumb/Breadcrumb';
import BtnBlue from '@/atoms/BtnBlue/BtnBlue';
import BtnTransparent from '@/atoms/BtnTransparent/BtnTransparent';
import Preloader from '@/atoms/Preloader/Preloader';
import globalClasses from '@/containers/App/Global.module.scss';
import { ACCEPT_CAR_TRANSFER_ACCEPTANCE, GET_CAR_TRANSFER_ACCEPTANCE } from '@/graphql/acts';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import ArrowLeftIcon from '@/icons/arrow-right.svg';
import ImagesIcon from '@/icons/images.svg';
import UserIcon from '@/icons/user.svg';
import { NUM_LOCALE, routes } from '@/utils/constants';
import { getErrorMessage } from '@/utils/validateMessages';
const CarTransferProfile = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12;
    const intl = useIntl();
    const navigate = useNavigate();
    const { id } = useParams();
    const { setActsPopup } = useAppDispatch();
    const userId = useAppSelector((state) => state.auth.user.id);
    const { loading, error, data } = useQuery(GET_CAR_TRANSFER_ACCEPTANCE, {
        variables: { transferAcceptanceId: id },
        skip: !id
    });
    const [acceptCarTransferAcceptance] = useMutation(ACCEPT_CAR_TRANSFER_ACCEPTANCE);
    const handleClick = () => { navigate(-1); };
    const handleAcceptAct = () => {
        acceptCarTransferAcceptance({
            variables: { transferAcceptanceId: id }
        }).then(() => {
            void message.success(intl.formatMessage({ id: 'common.actAccepted', defaultMessage: 'Акт прийнято' }), 3);
            navigate(routes.cabinet.f);
        }, (err) => { getErrorMessage(err, intl); });
    };
    if (loading)
        return React.createElement(Preloader, null);
    if (error)
        return React.createElement(Alert, { message: intl.formatMessage({ id: 'error.dataLoading', defaultMessage: 'Ошибка при загрузке данных' }), type: "error" });
    const getCarTransferAcceptance = data === null || data === void 0 ? void 0 : data.getCarTransferAcceptance;
    const bodyFront = Object.assign({ type: 'front', text: intl.formatMessage({ id: 'common.photo.front', defaultMessage: 'Спереду' }) }, (_a = getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.photos) === null || _a === void 0 ? void 0 : _a.bodyFront);
    const bodyBack = Object.assign({ type: 'back', text: intl.formatMessage({ id: 'common.photo.back', defaultMessage: 'Ззаду' }) }, (_b = getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.photos) === null || _b === void 0 ? void 0 : _b.bodyBack);
    const bodyRight = Object.assign({ type: 'right', text: intl.formatMessage({ id: 'common.photo.right', defaultMessage: 'З правого боку' }) }, (_c = getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.photos) === null || _c === void 0 ? void 0 : _c.bodyRight);
    const bodyLeft = Object.assign({ type: 'left', text: intl.formatMessage({ id: 'common.photo.left', defaultMessage: 'З лівого боку' }) }, (_d = getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.photos) === null || _d === void 0 ? void 0 : _d.bodyLeft);
    const dashboard = Object.assign({ type: 'dashboard', text: intl.formatMessage({ id: 'common.photo.dashboard', defaultMessage: 'Панель приборов' }) }, (_e = getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.photos) === null || _e === void 0 ? void 0 : _e.dashboard);
    const underHood = Object.assign({ type: 'underHood', text: intl.formatMessage({ id: 'common.photo.underHood', defaultMessage: 'Под капотом' }) }, (_f = getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.photos) === null || _f === void 0 ? void 0 : _f.underHood);
    const cabinFront = Object.assign({ type: 'front', text: intl.formatMessage({ id: 'common.photo.salonFront', defaultMessage: 'Салон спереду' }) }, (_g = getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.photos) === null || _g === void 0 ? void 0 : _g.cabinFront);
    const cabinBack = Object.assign({ type: 'back', text: intl.formatMessage({ id: 'common.photo.salonBack', defaultMessage: 'Салон ззаду' }) }, (_h = getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.photos) === null || _h === void 0 ? void 0 : _h.cabinBack);
    const trunc = Object.assign({ type: 'back', text: intl.formatMessage({ id: 'common.photo.trunc', defaultMessage: 'Фото відкритого багажника' }) }, (_j = getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.photos) === null || _j === void 0 ? void 0 : _j.trunc);
    const bodyPhotos = [bodyFront, bodyBack, bodyRight, bodyLeft];
    const cabinPhotos = [dashboard, underHood, cabinFront, cabinBack, trunc];
    const damagePhotos = getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.defectsPhotos;
    const isWaitingForAcceptance = (getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.status) === 'WAITING_ACCEPT';
    const isParkToDriver = (getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.type) === 'PARK_TO_DRIVER';
    const isDriverToDriver = (getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.type) === 'DRIVER_TO_DRIVER';
    const isTargetDriver = ((_k = getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.targetDriver) === null || _k === void 0 ? void 0 : _k.id) === userId;
    const isSourceDriver = ((_l = getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.sourceDriver) === null || _l === void 0 ? void 0 : _l.id) === userId;
    const detectControlsButtons = isWaitingForAcceptance && (isParkToDriver || isSourceDriver);
    const shouldShowControls = (isWaitingForAcceptance && isParkToDriver) || (isDriverToDriver && isTargetDriver);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classes.actInner, style: { paddingBottom: (detectControlsButtons || shouldShowControls) ? '60px' : '' } },
            React.createElement("div", { className: globalClasses.container },
                React.createElement("div", { className: globalClasses.back, onClick: handleClick },
                    React.createElement(ArrowLeftIcon, null)),
                React.createElement(Breadcrumb, { black: true, items: [{
                            id: 'home',
                            path: routes.cabinet.f,
                            text: intl.formatMessage({ id: 'common.toHome', defaultMessage: 'На головну' })
                        }] }),
                React.createElement("div", { className: globalClasses.innerPageTitle },
                    intl.formatMessage({ id: 'common.act', defaultMessage: 'Акт' }),
                    " \u2116", getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 :
                    getCarTransferAcceptance.humanId),
                React.createElement("div", { className: globalClasses.box },
                    React.createElement("div", { className: globalClasses.boxTitle }, intl.formatMessage({ id: 'acts.generalInformation', defaultMessage: 'Загальна інформація' })),
                    React.createElement("div", { className: classes.boxHead },
                        React.createElement("div", { className: classes.boxLeft },
                            React.createElement("div", { className: classes.number },
                                "\u2116", getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 :
                                getCarTransferAcceptance.humanId),
                            React.createElement("div", { className: classes.date },
                                intl.formatMessage({ id: 'common.from', defaultMessage: 'Від' }),
                                " ",
                                (getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.createdAt) ? dayjs(getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.createdAt).format('DD.MM.YYYY, HH:mm') : '—')),
                        React.createElement("div", { className: classes.boxRight }, (getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.status) && (React.createElement("div", { className: classes.status, style: { background: getCarTransferAcceptanceBg(getCarTransferAcceptance.status) } },
                            React.createElement("span", { className: classes.statusIcon }, getCarTransferAcceptanceIcon(16, 16, getCarTransferAcceptanceColor(getCarTransferAcceptance.status))[getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.status]),
                            React.createElement("span", { style: { color: getCarTransferAcceptanceColor(getCarTransferAcceptance.status) } }, getCarTransferAcceptanceStatus(getCarTransferAcceptance.status, intl)))))),
                    (getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.rejectionComment) && (React.createElement("div", { className: classes.commentBox },
                        React.createElement("div", { className: globalClasses.boxTitle }, intl.formatMessage({ id: 'acts.rejectedReason', defaultMessage: 'Причина відхилення' })),
                        React.createElement("div", { className: classes.boxInnerText }, (getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.rejectionComment) || '—'))),
                    React.createElement("div", { className: classes.boxInner },
                        React.createElement("div", { className: classes.boxInnerTitle }, intl.formatMessage({ id: 'acts.givesCar', defaultMessage: 'Віддає авто' })),
                        React.createElement("div", { className: classes.user },
                            React.createElement("div", { className: classes.userAva },
                                React.createElement(UserIcon, null),
                                ((_m = getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.sourceUser) === null || _m === void 0 ? void 0 : _m.avatarUrl) && React.createElement("img", { alt: "avatar", src: (_o = getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.sourceUser) === null || _o === void 0 ? void 0 : _o.avatarUrl }),
                                ((_p = getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.sourceDriver) === null || _p === void 0 ? void 0 : _p.avatarUrl) && React.createElement("img", { alt: "avatar", src: (_q = getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.sourceDriver) === null || _q === void 0 ? void 0 : _q.avatarUrl })),
                            React.createElement("div", { className: classes.userBox },
                                React.createElement("div", { className: classes.userName }, (getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.sourceUser)
                                    ? `${(_s = (_r = getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.sourceUser) === null || _r === void 0 ? void 0 : _r.firstName) === null || _s === void 0 ? void 0 : _s.charAt(0)}. ${(_u = (_t = getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.sourceUser) === null || _t === void 0 ? void 0 : _t.lastName) === null || _u === void 0 ? void 0 : _u.charAt(0)}.`
                                    : `${(_v = getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.sourceDriver) === null || _v === void 0 ? void 0 : _v.firstName} ${(_w = getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.sourceDriver) === null || _w === void 0 ? void 0 : _w.lastName}`),
                                React.createElement("div", { className: classes.userPosition }, (getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.sourceUser)
                                    ? intl.formatMessage({ id: 'common.manager', defaultMessage: 'Менеджер' })
                                    : intl.formatMessage({ id: 'common.driver', defaultMessage: 'Водій' })))),
                        React.createElement("div", { className: classes.boxInnerTitle }, intl.formatMessage({ id: 'acts.acceptsCar', defaultMessage: 'Приймає авто' })),
                        React.createElement("div", { className: classes.user },
                            React.createElement("div", { className: classes.userAva },
                                React.createElement(UserIcon, null),
                                ((_x = getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.targetUser) === null || _x === void 0 ? void 0 : _x.avatarUrl) && React.createElement("img", { alt: "avatar", src: (_y = getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.targetUser) === null || _y === void 0 ? void 0 : _y.avatarUrl }),
                                ((_z = getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.targetDriver) === null || _z === void 0 ? void 0 : _z.avatarUrl) && React.createElement("img", { alt: "avatar", src: (_0 = getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.targetDriver) === null || _0 === void 0 ? void 0 : _0.avatarUrl })),
                            React.createElement("div", { className: classes.userBox },
                                React.createElement("div", { className: classes.userName },
                                    (getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.targetUser) && `${(_2 = (_1 = getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.targetUser) === null || _1 === void 0 ? void 0 : _1.firstName) === null || _2 === void 0 ? void 0 : _2.charAt(0)}. ${(_4 = (_3 = getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.targetUser) === null || _3 === void 0 ? void 0 : _3.lastName) === null || _4 === void 0 ? void 0 : _4.charAt(0)}.`,
                                    (getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.targetDriver) && `${(_5 = getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.targetDriver) === null || _5 === void 0 ? void 0 : _5.firstName} ${(_6 = getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.targetDriver) === null || _6 === void 0 ? void 0 : _6.lastName}`),
                                React.createElement("div", { className: classes.userPosition },
                                    (getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.targetUser) && intl.formatMessage({ id: 'common.manager', defaultMessage: 'Менеджер' }),
                                    (getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.targetDriver) && intl.formatMessage({ id: 'common.driver', defaultMessage: 'Водій' }),
                                    !(getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.targetDriver) && !(getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.targetUser) && intl.formatMessage({ id: 'common.park', defaultMessage: 'Автопарк' })))),
                        (getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.comment) && (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: classes.boxInnerTitle }, intl.formatMessage({ id: 'common.comment', defaultMessage: 'Коментар' })),
                            React.createElement("div", { className: classes.boxInnerText }, getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.comment))))),
                React.createElement("div", { className: globalClasses.box },
                    React.createElement("div", { className: globalClasses.boxTitle }, intl.formatMessage({ id: 'common.car', defaultMessage: 'авто' })),
                    React.createElement("div", { className: classes.number }, (_8 = (_7 = getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.car) === null || _7 === void 0 ? void 0 : _7.licensePlate) !== null && _8 !== void 0 ? _8 : '—'),
                    React.createElement("div", { className: classes.model }, (_10 = (_9 = getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.car) === null || _9 === void 0 ? void 0 : _9.model) !== null && _10 !== void 0 ? _10 : '—'),
                    React.createElement("div", { className: classes.boxInner },
                        React.createElement("div", { className: classes.boxInnerTitle }, intl.formatMessage({ id: 'common.trackerOdometer', defaultMessage: 'Пробіг по GPS' })),
                        React.createElement("div", { className: classes.boxInnerText }, (getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.trackerOdometer) ? (_11 = ((getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.trackerOdometer) / 1000)) === null || _11 === void 0 ? void 0 : _11.toLocaleString(NUM_LOCALE, { maximumFractionDigits: 0 }) : '—'))),
                React.createElement("div", { className: globalClasses.box },
                    React.createElement("div", { className: globalClasses.boxTitle }, intl.formatMessage({ id: 'common.act', defaultMessage: 'Акт' })),
                    React.createElement("div", { className: classes.boxInner },
                        React.createElement("div", { className: classes.boxInnerTitle }, intl.formatMessage({ id: 'common.vehicleTechnicalPassport', defaultMessage: 'Тех. паспорт' })),
                        React.createElement("div", { className: classes.boxInnerText }, (getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.technicalPassport) ? getTechnicalPassportStatus(getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.technicalPassport, intl) : '—'),
                        React.createElement("div", { className: classes.boxInnerTitle }, intl.formatMessage({ id: 'common.odometer', defaultMessage: 'Пробіг' })),
                        React.createElement("div", { className: classes.boxInnerText }, (getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.odometer) ? (_12 = ((getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.odometer) / 1000)) === null || _12 === void 0 ? void 0 : _12.toLocaleString(NUM_LOCALE, { maximumFractionDigits: 0 }) : '—'),
                        React.createElement("div", { className: classes.boxInnerTitle }, intl.formatMessage({ id: 'common.oilLevel', defaultMessage: 'Рівень мастила' })),
                        React.createElement("div", { className: classes.boxInnerText }, (getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.oilLevel) ? getTechnicalLiquidsStatus(getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.oilLevel, intl) : '—'),
                        React.createElement("div", { className: classes.boxInnerTitle }, intl.formatMessage({ id: 'common.coolingLiquidLevel', defaultMessage: 'Рівень охолоджуючої рідини' })),
                        React.createElement("div", { className: classes.boxInnerText }, (getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.coolingLiquidLevel) ? getTechnicalLiquidsStatus(getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.coolingLiquidLevel, intl) : '—')),
                    React.createElement("div", { className: classes.photo },
                        React.createElement("div", { className: classes.photoHead },
                            React.createElement("div", { className: classes.photoHeadTitle }, intl.formatMessage({ id: 'common.photoOutside', defaultMessage: 'Фото ззовні' })),
                            React.createElement("div", { className: classes.photoHeadIcon },
                                React.createElement(ImagesIcon, { width: 17, height: 17 }),
                                `${bodyPhotos === null || bodyPhotos === void 0 ? void 0 : bodyPhotos.length} `,
                                intl.formatMessage({ id: 'common.image', defaultMessage: 'зображення' }))),
                        React.createElement(Image.PreviewGroup, null,
                            React.createElement("div", { className: classes.photoBody }, bodyPhotos === null || bodyPhotos === void 0 ? void 0 : bodyPhotos.map((photo) => (React.createElement("div", { className: classes.photoItem, key: uuidv4() },
                                React.createElement("div", { className: classes.photoItemImg },
                                    React.createElement(Image, { src: (photo === null || photo === void 0 ? void 0 : photo.thumbnail) || (photo === null || photo === void 0 ? void 0 : photo.original), preview: { src: photo === null || photo === void 0 ? void 0 : photo.original } })),
                                React.createElement("div", { className: classes.photoItemText }, photo === null || photo === void 0 ? void 0 : photo.text))))))),
                    React.createElement("div", { className: classes.photo },
                        React.createElement("div", { className: classes.photoHead },
                            React.createElement("div", { className: classes.photoHeadTitle }, intl.formatMessage({ id: 'common.photoInside', defaultMessage: 'Фото зсередини' })),
                            React.createElement("div", { className: classes.photoHeadIcon },
                                React.createElement(ImagesIcon, { width: 17, height: 17 }),
                                `${cabinPhotos === null || cabinPhotos === void 0 ? void 0 : cabinPhotos.length} `,
                                intl.formatMessage({ id: 'common.image', defaultMessage: 'зображення' }))),
                        React.createElement(Image.PreviewGroup, null,
                            React.createElement("div", { className: classes.photoBody }, cabinPhotos === null || cabinPhotos === void 0 ? void 0 : cabinPhotos.map((photo) => (React.createElement("div", { className: classes.photoItem, key: uuidv4() },
                                React.createElement("div", { className: classes.photoItemImg },
                                    React.createElement(Image, { src: (photo === null || photo === void 0 ? void 0 : photo.thumbnail) || (photo === null || photo === void 0 ? void 0 : photo.original), preview: { src: photo === null || photo === void 0 ? void 0 : photo.original } })),
                                React.createElement("div", { className: classes.photoItemText }, photo === null || photo === void 0 ? void 0 : photo.text))))))),
                    React.createElement("div", { className: classes.photo },
                        React.createElement("div", { className: classes.photoHead },
                            React.createElement("div", { className: classes.photoHeadTitle }, intl.formatMessage({ id: 'common.damage', defaultMessage: 'Пошкодження' })),
                            ((getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.defects) === 'YES') && (damagePhotos === null || damagePhotos === void 0 ? void 0 : damagePhotos.length) && (React.createElement("div", { className: classes.photoHeadIcon },
                                React.createElement(ImagesIcon, { width: 17, height: 17 }),
                                `${damagePhotos === null || damagePhotos === void 0 ? void 0 : damagePhotos.length} `,
                                intl.formatMessage({ id: 'common.image', defaultMessage: 'зображення' })))),
                        ((getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.defects) === 'NO') && (!(damagePhotos === null || damagePhotos === void 0 ? void 0 : damagePhotos.length))
                            ? (React.createElement("div", { className: classes.noDefects }, intl.formatMessage({ id: 'common.noDamage', defaultMessage: 'Пошкодження відсутні' })))
                            : (React.createElement(Image.PreviewGroup, null,
                                React.createElement("div", { className: classes.photoBody }, damagePhotos === null || damagePhotos === void 0 ? void 0 : damagePhotos.map((photo) => (React.createElement("div", { className: classes.photoItem, key: uuidv4() },
                                    React.createElement("div", { className: classes.photoItemImg },
                                        React.createElement(Image, { src: (photo === null || photo === void 0 ? void 0 : photo.thumbnail) || (photo === null || photo === void 0 ? void 0 : photo.original), preview: { src: photo === null || photo === void 0 ? void 0 : photo.original } })),
                                    React.createElement("div", { className: classes.photoItemText }, photo === null || photo === void 0 ? void 0 : photo.text)))))))))),
            shouldShowControls && (React.createElement("div", { className: globalClasses.controlsFixed },
                React.createElement(BtnTransparent, { onClick: () => setActsPopup('rejectAct', true, { id }), text: intl.formatMessage({ id: 'common.reject', defaultMessage: 'Відхилити' }) }),
                React.createElement(BtnBlue, { onClick: handleAcceptAct, text: intl.formatMessage({ id: 'common.accept', defaultMessage: 'Прийняти' }) }))),
            isSourceDriver && isWaitingForAcceptance && (React.createElement("div", { className: globalClasses.controlsFixed },
                React.createElement(BtnTransparent, { onClick: () => setActsPopup('cancelAct', true, { id, humanId: getCarTransferAcceptance === null || getCarTransferAcceptance === void 0 ? void 0 : getCarTransferAcceptance.humanId }), text: intl.formatMessage({ id: 'common.cancelAct', defaultMessage: 'Скасувати акт' }) })))),
        React.createElement(RejectAct, null),
        React.createElement(CancelAct, null)));
};
export default CarTransferProfile;
