var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import { Form, Input, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import classes from './CreateOfficeTrips.module.scss';
import Breadcrumb from '@/atoms/Breadcrumb/Breadcrumb';
import BtnBlue from '@/atoms/BtnBlue/BtnBlue';
import BtnTransparent from '@/atoms/BtnTransparent/BtnTransparent';
import PhoneMaskInput from '@/atoms/PhoneMaskInput/PhoneMaskInput';
import StationsSelect from '@/atoms/StationsSelect/StationsSelect';
import globalClasses from '@/containers/App/Global.module.scss';
import { CREATE_TRIP_REQUEST, GET_ACTIVE_TRIP } from '@/graphql/officeTrips';
import ArrowLeftIcon from '@/icons/arrow-right.svg';
import { routes } from '@/utils/constants';
import { getErrorMessage } from '@/utils/validateMessages';
const CreateOfficeTrips = () => {
    const [loadingState, setLoadingState] = useState(false);
    const [selectedPickUpStation, setSelectedPickUpStation] = useState(null);
    const [selectedDestinationStation, setSelectedDestinationStation] = useState(null);
    const intl = useIntl();
    const navigate = useNavigate();
    const handleClick = () => { navigate(-1); };
    const [form] = Form.useForm();
    const [createTripRequest] = useMutation(CREATE_TRIP_REQUEST, {
        refetchQueries: () => [GET_ACTIVE_TRIP]
    });
    const onFinish = (values) => __awaiter(void 0, void 0, void 0, function* () {
        setLoadingState(true);
        const { clientName, clientPhone, cost, comment } = values;
        createTripRequest({
            variables: {
                createTripRequestInput: Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ cost: +cost }, selectedPickUpStation && (selectedPickUpStation === null || selectedPickUpStation === void 0 ? void 0 : selectedPickUpStation.value) !== (selectedPickUpStation === null || selectedPickUpStation === void 0 ? void 0 : selectedPickUpStation.label) && { pickUpStationId: selectedPickUpStation === null || selectedPickUpStation === void 0 ? void 0 : selectedPickUpStation.value }), selectedPickUpStation && (selectedPickUpStation === null || selectedPickUpStation === void 0 ? void 0 : selectedPickUpStation.value) === (selectedPickUpStation === null || selectedPickUpStation === void 0 ? void 0 : selectedPickUpStation.label) && { pickUpAddress: selectedPickUpStation === null || selectedPickUpStation === void 0 ? void 0 : selectedPickUpStation.value }), selectedDestinationStation && (selectedDestinationStation === null || selectedDestinationStation === void 0 ? void 0 : selectedDestinationStation.value) !== (selectedDestinationStation === null || selectedDestinationStation === void 0 ? void 0 : selectedDestinationStation.label) && { destinationStationId: selectedDestinationStation === null || selectedDestinationStation === void 0 ? void 0 : selectedDestinationStation.value }), selectedDestinationStation && (selectedDestinationStation === null || selectedDestinationStation === void 0 ? void 0 : selectedDestinationStation.value) === (selectedDestinationStation === null || selectedDestinationStation === void 0 ? void 0 : selectedDestinationStation.label) && { destinationAddress: selectedDestinationStation === null || selectedDestinationStation === void 0 ? void 0 : selectedDestinationStation.value }), clientName && { clientName }), clientPhone && { clientPhone }), comment && { comment })
            }
        }).then(() => {
            void message.success(intl.formatMessage({ id: 'common.tripCreated', defaultMessage: 'Поїздка створена' }), 3);
            navigate(routes.officeTrips.f);
        }, (err) => {
            getErrorMessage(err, intl);
            setLoadingState(false);
        });
    });
    return (React.createElement("div", { className: classes.holder },
        React.createElement("div", { className: globalClasses.innerPage },
            React.createElement("div", { className: globalClasses.container },
                React.createElement("div", { className: globalClasses.back, onClick: handleClick },
                    React.createElement(ArrowLeftIcon, null)),
                React.createElement(Breadcrumb, { black: true, items: [
                        {
                            id: 'home',
                            path: routes.cabinet.f,
                            text: intl.formatMessage({ id: 'common.toHome', defaultMessage: 'На головну' })
                        },
                        {
                            id: 'officeTrips',
                            path: routes.officeTrips.f,
                            text: 'Office trips'
                        }
                    ] }),
                React.createElement("div", { className: globalClasses.innerPageTitle }, intl.formatMessage({ id: 'common.createPersonalTrip', defaultMessage: 'Створити особисту поїздку' })),
                React.createElement("div", { className: globalClasses.innerPageBox },
                    React.createElement(Form, { form: form, autoComplete: "off", name: "createTripRequest", onFinish: onFinish, scrollToFirstError: true },
                        React.createElement(Form.Item, { label: intl.formatMessage({ id: 'common.clientName', defaultMessage: 'Імʼя клієнта' }), name: "clientName" },
                            React.createElement(Input, { placeholder: intl.formatMessage({ id: 'common.clientName', defaultMessage: 'Імʼя клієнта' }) })),
                        React.createElement(PhoneMaskInput, { form: form, name: "clientPhone", label: intl.formatMessage({ id: 'common.clientPhone', defaultMessage: 'Телефон клиента' }) }),
                        React.createElement(StationsSelect, { forForm: true, isRequired: true, message: intl.formatMessage({ id: 'common.enterPickUpAddress', defaultMessage: 'Введіть адресу посадки' }), form: form, name: "pickUpStationId", setAddressIds: setSelectedPickUpStation, addressIds: selectedPickUpStation, label: intl.formatMessage({ id: 'common.pickUpAddress', defaultMessage: 'Адреса посадки' }) }),
                        React.createElement(StationsSelect, { forForm: true, isRequired: true, message: intl.formatMessage({ id: 'common.enterDestinationAddress', defaultMessage: 'Введіть адресу завершення' }), form: form, name: "destinationStationId", setAddressIds: setSelectedDestinationStation, addressIds: selectedDestinationStation, label: intl.formatMessage({ id: 'common.destinationAddress', defaultMessage: 'Адреса завершення' }) }),
                        React.createElement(Form.Item, { label: intl.formatMessage({ id: 'common.cost', defaultMessage: 'Вартість (Розрахункова)' }), name: "cost", normalize: value => (value.replace(/[^0-9.]/g, '').match(/^\d*(\.\d{0,2})?/g) || [''])[0], rules: [{ required: true, message: intl.formatMessage({ id: 'common.enterCost', defaultMessage: 'Введіть вартість' }) }] },
                            React.createElement(Input, { placeholder: intl.formatMessage({ id: 'common.enterCost', defaultMessage: 'Введіть вартість' }) })),
                        React.createElement(Form.Item, { label: intl.formatMessage({ id: 'common.comment', defaultMessage: 'Коментар' }), name: "comment" },
                            React.createElement(TextArea, { placeholder: intl.formatMessage({ id: 'common.enterComment', defaultMessage: 'Введіть коментар' }) })),
                        React.createElement("div", { className: globalClasses.controlsFixed },
                            React.createElement(Link, { to: routes.officeTrips.f },
                                React.createElement(BtnTransparent, { text: intl.formatMessage({ id: 'common.cancel', defaultMessage: 'Скасувати' }) })),
                            React.createElement(BtnBlue, { loading: loadingState, type: "submit", text: intl.formatMessage({ id: 'common.create', defaultMessage: 'Створити' }) }))))))));
};
export default CreateOfficeTrips;
