export default {
    selectedCountryAriaLabel: 'Tanlangan davlat',
    noCountrySelected: 'Davlat tanlanmadi',
    countryListAriaLabel: 'Davlatlar roʻyxati',
    searchPlaceholder: 'Qidirish',
    zeroSearchResults: 'Natijalar topilmadi',
    oneSearchResult: '1 ta natija topildi',
    ac: 'Assension oroli',
    xk: 'Kosovo'
};
