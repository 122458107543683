export default {
    selectedCountryAriaLabel: 'Wybrany kraj',
    noCountrySelected: 'Nie wybrano kraju',
    countryListAriaLabel: 'Lista krajów',
    searchPlaceholder: 'Szukaj',
    zeroSearchResults: 'Nie znaleziono wyników',
    oneSearchResult: 'Znaleziono 1 wynik',
    ac: 'Wyspa Wniebowstąpienia',
    xk: 'Kosowo'
};
