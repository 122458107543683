import { useQuery } from '@apollo/client';
import { Alert } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import classes from './MyCar.module.scss';
import BtnBlue from '@/atoms/BtnBlue/BtnBlue';
import BtnTransparent from '@/atoms/BtnTransparent/BtnTransparent';
import Preloader from '@/atoms/Preloader/Preloader';
import { GET_CAR_TRANSFER_ACCEPTANCE_INFO } from '@/graphql/acts';
import useAppSelector from '@/hooks/useAppSelector';
import ActIcon from '@/icons/act.svg';
import ActsIcon from '@/icons/acts.svg';
import { NUM_LOCALE, routes } from '@/utils/constants';
const MyCar = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const intl = useIntl();
    const userId = useAppSelector((state) => state.auth.user.id);
    const { loading, error, data } = useQuery(GET_CAR_TRANSFER_ACCEPTANCE_INFO);
    // TODO: put it in a hook
    // useEffect(() => {
    //   const errorCode = eventError?.graphQLErrors[0]?.extensions?.code || ''
    //   if (errorCode === 'BLOCKED') {
    //     setAccessToken(null)
    //     dispatch(authSlice.actions.authSetError('BLOCKED_AUTHORIZED'))
    //     dispatch(authSlice.actions.authSetCompanyId(''))
    //   }
    // }, [eventError])
    if (loading)
        return React.createElement(Preloader, { inner: true });
    if (error)
        return React.createElement(Alert, { message: intl.formatMessage({ id: 'error.dataLoading', defaultMessage: 'Ошибка при загрузке данных' }), type: "error" });
    const activeCarTransferAcceptance = (_a = data === null || data === void 0 ? void 0 : data.getCarTransferAcceptanceInfo) === null || _a === void 0 ? void 0 : _a.activeCarTransferAcceptance;
    const lastRejectedCarTransferAcceptance = (_b = data === null || data === void 0 ? void 0 : data.getCarTransferAcceptanceInfo) === null || _b === void 0 ? void 0 : _b.lastRejectedCarTransferAcceptance;
    return (React.createElement("div", { className: classes.myCar }, activeCarTransferAcceptance
        ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: classes.img }, ((_c = activeCarTransferAcceptance === null || activeCarTransferAcceptance === void 0 ? void 0 : activeCarTransferAcceptance.car) === null || _c === void 0 ? void 0 : _c.photoUrl)
                ? React.createElement("img", { src: (_d = activeCarTransferAcceptance === null || activeCarTransferAcceptance === void 0 ? void 0 : activeCarTransferAcceptance.car) === null || _d === void 0 ? void 0 : _d.photoUrl, width: 361, height: 151, alt: "car" })
                : React.createElement("div", { className: classes.noImg }, intl.formatMessage({ id: 'common.noPhotoCar', defaultMessage: '' }))),
            React.createElement("div", { className: classes.head },
                React.createElement("div", { className: classes.title }, intl.formatMessage({ id: 'common.myCar', defaultMessage: 'Моє авто' })),
                React.createElement("div", { className: classes.licensePlate }, (_f = (_e = activeCarTransferAcceptance === null || activeCarTransferAcceptance === void 0 ? void 0 : activeCarTransferAcceptance.car) === null || _e === void 0 ? void 0 : _e.licensePlate) !== null && _f !== void 0 ? _f : '—'),
                React.createElement("div", { className: classes.info }, (_g = activeCarTransferAcceptance === null || activeCarTransferAcceptance === void 0 ? void 0 : activeCarTransferAcceptance.car) === null || _g === void 0 ? void 0 : _g.model),
                (activeCarTransferAcceptance === null || activeCarTransferAcceptance === void 0 ? void 0 : activeCarTransferAcceptance.status) === 'WAITING_ACCEPT' && (React.createElement("div", { className: classes.act },
                    React.createElement("div", { className: classes.actHead },
                        React.createElement("div", { className: classes.actHeadIcon },
                            React.createElement(ActIcon, { width: 36, height: 36 })),
                        React.createElement("div", { className: classes.actHeadBox },
                            React.createElement("div", { className: classes.actNumber },
                                intl.formatMessage({ id: 'common.act', defaultMessage: 'Акт' }),
                                " \u2116", (_h = activeCarTransferAcceptance === null || activeCarTransferAcceptance === void 0 ? void 0 : activeCarTransferAcceptance.humanId) !== null && _h !== void 0 ? _h : '—'),
                            (((activeCarTransferAcceptance === null || activeCarTransferAcceptance === void 0 ? void 0 : activeCarTransferAcceptance.type) === 'PARK_TO_DRIVER') || (((activeCarTransferAcceptance === null || activeCarTransferAcceptance === void 0 ? void 0 : activeCarTransferAcceptance.type) === 'DRIVER_TO_DRIVER') && ((activeCarTransferAcceptance === null || activeCarTransferAcceptance === void 0 ? void 0 : activeCarTransferAcceptance.targetDriverId) === userId))) && (React.createElement("div", { className: classes.actText }, intl.formatMessage({ id: 'acts.receiveCar', defaultMessage: 'На отримання авто' }))),
                            (((activeCarTransferAcceptance === null || activeCarTransferAcceptance === void 0 ? void 0 : activeCarTransferAcceptance.type) === 'DRIVER_TO_PARK') || (((activeCarTransferAcceptance === null || activeCarTransferAcceptance === void 0 ? void 0 : activeCarTransferAcceptance.type) === 'DRIVER_TO_DRIVER') && ((activeCarTransferAcceptance === null || activeCarTransferAcceptance === void 0 ? void 0 : activeCarTransferAcceptance.sourceDriverId) === userId))) && (React.createElement("div", { className: classes.actText }, intl.formatMessage({ id: 'common.actReturnCar', defaultMessage: 'Акт на здачу авто' }))))),
                    React.createElement(Link, { to: `${routes.carTransfer.f}/${activeCarTransferAcceptance === null || activeCarTransferAcceptance === void 0 ? void 0 : activeCarTransferAcceptance.id}`, style: { display: 'block' } },
                        React.createElement(BtnTransparent, { fullWidth: true, text: intl.formatMessage({ id: 'common.viewAct', defaultMessage: 'Переглянути акт' }) })))),
                (activeCarTransferAcceptance === null || activeCarTransferAcceptance === void 0 ? void 0 : activeCarTransferAcceptance.status) === 'ACCEPTED' && (React.createElement("div", { className: classes.act },
                    React.createElement("div", { className: classes.actRow },
                        React.createElement("div", { className: classes.actRowTitle }, intl.formatMessage({ id: 'common.trackerOdometer', defaultMessage: 'Пробіг по GPS' })),
                        React.createElement("div", { className: classes.actRowText }, (activeCarTransferAcceptance === null || activeCarTransferAcceptance === void 0 ? void 0 : activeCarTransferAcceptance.trackerOdometer) ? (_j = ((activeCarTransferAcceptance === null || activeCarTransferAcceptance === void 0 ? void 0 : activeCarTransferAcceptance.trackerOdometer) / 1000)) === null || _j === void 0 ? void 0 : _j.toLocaleString(NUM_LOCALE, { maximumFractionDigits: 0 }) : '—')),
                    React.createElement("div", { className: classes.actRow },
                        React.createElement("div", { className: classes.actRowTitle }, intl.formatMessage({ id: 'common.actReceipt', defaultMessage: 'Акт отримання' })),
                        React.createElement("div", { className: classes.actRowText },
                            React.createElement(ActsIcon, null),
                            "\u2116",
                            `${activeCarTransferAcceptance === null || activeCarTransferAcceptance === void 0 ? void 0 : activeCarTransferAcceptance.humanId} ${intl.formatMessage({ id: 'common.from', defaultMessage: 'від' })} ${dayjs(activeCarTransferAcceptance === null || activeCarTransferAcceptance === void 0 ? void 0 : activeCarTransferAcceptance.createdAt).format('DD.MM.YYYY')}`),
                        React.createElement(Link, { to: `${routes.carTransfer.f}/${activeCarTransferAcceptance === null || activeCarTransferAcceptance === void 0 ? void 0 : activeCarTransferAcceptance.id}`, style: { display: 'block' } },
                            React.createElement("div", { className: classes.link }, intl.formatMessage({ id: 'common.viewAct', defaultMessage: 'Переглянути акт' })))),
                    lastRejectedCarTransferAcceptance && (React.createElement("div", { className: classes.actRow },
                        React.createElement("div", { className: classes.actRowTitle },
                            intl.formatMessage({ id: 'common.actTransfer', defaultMessage: 'Акт передачі' }),
                            (lastRejectedCarTransferAcceptance === null || lastRejectedCarTransferAcceptance === void 0 ? void 0 : lastRejectedCarTransferAcceptance.status) === 'REJECTED' && React.createElement("span", { style: { color: '#e53a3a' } }, intl.formatMessage({ id: 'officeTrips.rejected', defaultMessage: 'Відхилено' })),
                            (lastRejectedCarTransferAcceptance === null || lastRejectedCarTransferAcceptance === void 0 ? void 0 : lastRejectedCarTransferAcceptance.status) === 'WAITING_ACCEPT' && React.createElement("span", { style: { color: '#A7A7A7' } }, intl.formatMessage({ id: 'acts.waitingAnswers', defaultMessage: 'Очікує відповіді' }))),
                        React.createElement("div", { className: classes.actRowText },
                            React.createElement(ActsIcon, null),
                            "\u2116",
                            `${lastRejectedCarTransferAcceptance === null || lastRejectedCarTransferAcceptance === void 0 ? void 0 : lastRejectedCarTransferAcceptance.humanId} ${intl.formatMessage({ id: 'common.from', defaultMessage: 'від' })} ${dayjs(lastRejectedCarTransferAcceptance === null || lastRejectedCarTransferAcceptance === void 0 ? void 0 : lastRejectedCarTransferAcceptance.createdAt).format('DD.MM.YYYY')}`),
                        React.createElement(Link, { to: `${routes.carTransfer.f}/${lastRejectedCarTransferAcceptance === null || lastRejectedCarTransferAcceptance === void 0 ? void 0 : lastRejectedCarTransferAcceptance.id}`, style: { display: 'block' } },
                            React.createElement("div", { className: classes.link }, intl.formatMessage({ id: 'common.viewAct', defaultMessage: 'Переглянути акт' })))))))),
            (activeCarTransferAcceptance === null || activeCarTransferAcceptance === void 0 ? void 0 : activeCarTransferAcceptance.status) === 'ACCEPTED' && (React.createElement("div", { className: classes.returnCarControls },
                React.createElement("div", { className: classes.returnCarControlsBox },
                    React.createElement(Link, { state: { activeCarTransferAcceptance }, to: routes.returnCar.f },
                        React.createElement(BtnBlue, { high: true, fullWidth: true, text: intl.formatMessage({ id: 'common.returnCar', defaultMessage: 'Передати автомобіль' }) })))))))
        : React.createElement("div", { className: classes.noCar }, intl.formatMessage({ id: 'common.noCar', defaultMessage: 'Немає авто' }))));
};
export default MyCar;
