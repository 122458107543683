var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import { Form, Modal, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import BtnBlue from '@/atoms/BtnBlue/BtnBlue';
import BtnTransparent from '@/atoms/BtnTransparent/BtnTransparent';
import globalClasses from '@/containers/App/Global.module.scss';
import { REJECT_CAR_TRANSFER_ACCEPTANCE } from '@/graphql/acts';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import { routes } from '@/utils/constants';
import { getErrorMessage } from '@/utils/validateMessages';
const RejectAct = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const { setActsPopup } = useAppDispatch();
    const { visibility, defaultValues } = useAppSelector((state) => state.acts.modals.rejectAct);
    const transferAcceptanceId = defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.id;
    const [rejectCarTransferAcceptance] = useMutation(REJECT_CAR_TRANSFER_ACCEPTANCE);
    const onFinish = (values) => __awaiter(void 0, void 0, void 0, function* () {
        const { comment } = values;
        rejectCarTransferAcceptance({
            variables: {
                rejectCarTransferAcceptanceInput: {
                    transferAcceptanceId,
                    rejectionComment: comment
                }
            }
        }).then(() => {
            void message.success(intl.formatMessage({ id: 'acts.rejected', defaultMessage: 'Акт відхилено' }), 3);
            navigate(routes.cabinet.f);
        }, (err) => { getErrorMessage(err, intl); });
    });
    return (React.createElement(Modal, { title: intl.formatMessage({ id: 'acts.rejectPurpose', defaultMessage: 'Будь ласка, вкажіть причину відхилення акту' }), open: visibility, onCancel: () => setActsPopup('rejectAct', false), footer: false },
        React.createElement(Form, { autoComplete: "off", name: "reject-form", onFinish: onFinish, scrollToFirstError: true },
            React.createElement(Form.Item, { label: intl.formatMessage({ id: 'common.comment', defaultMessage: 'Коментар' }), name: "comment", rules: [{ required: true, message: intl.formatMessage({ id: 'error.comment', defaultMessage: 'Please input your comment!' }) }] },
                React.createElement(TextArea, { placeholder: intl.formatMessage({ id: 'common.comment', defaultMessage: 'Коментар' }) })),
            React.createElement("div", { className: globalClasses.controlsFixed },
                React.createElement(BtnTransparent, { type: 'button', onClick: () => setActsPopup('rejectAct', false), text: intl.formatMessage({ id: 'common.cancel', defaultMessage: 'Скасувати' }) }),
                React.createElement(BtnBlue, { type: "submit", text: intl.formatMessage({ id: 'common.rejectAct', defaultMessage: 'Відхилити акт' }) })))));
};
export default RejectAct;
