import { useMutation } from '@apollo/client';
import { Alert, message } from 'antd';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import StationItem from './StationItem/StationItem';
import classes from './StationSelection.module.scss';
import BtnBlue from '@/atoms/BtnBlue/BtnBlue';
import Preloader from '@/atoms/Preloader/Preloader';
import globalClasses from '@/containers/App/Global.module.scss';
import { GET_DRIVER_STATUS, LIST_TRIP_STATIONS, SET_STATUS_WAITING_FOR_ORDER } from '@/graphql/officeTrips';
import ArrowLeftIcon from '@/icons/arrow-right.svg';
import { getErrorMessage } from '@/utils/validateMessages';
const StationSelection = ({ setToggleStations, listStations, error, loading }) => {
    const [selectedStation, setSelectedStation] = useState(null);
    const intl = useIntl();
    const locationState = useLocation();
    const navigate = useNavigate();
    const [setStatusWaitingForOrder] = useMutation(SET_STATUS_WAITING_FOR_ORDER, {
        refetchQueries: () => [GET_DRIVER_STATUS, LIST_TRIP_STATIONS]
    });
    const setStatusWaiting = () => {
        if (selectedStation) {
            setStatusWaitingForOrder({
                variables: {
                    setStatusWaitingForOrderInput: { stationId: selectedStation }
                }
            }).then(() => {
                var _a;
                setToggleStations(false);
                if ((_a = locationState === null || locationState === void 0 ? void 0 : locationState.state) === null || _a === void 0 ? void 0 : _a.toggleStations) {
                    navigate(locationState === null || locationState === void 0 ? void 0 : locationState.pathname, { replace: true, state: Object.assign(Object.assign({}, locationState === null || locationState === void 0 ? void 0 : locationState.state), { toggleStations: false }) });
                }
                void message.success(intl.formatMessage({ id: 'common.statusChanged', defaultMessage: 'Статус змінено' }), 3);
            }, (err) => { getErrorMessage(err, intl); });
        }
        else {
            void message.error(intl.formatMessage({ id: 'officeTrips.choosePlace', defaultMessage: 'Выберите место ожидания' }), 3);
        }
    };
    const hideStations = () => {
        var _a;
        setToggleStations(false);
        if ((_a = locationState === null || locationState === void 0 ? void 0 : locationState.state) === null || _a === void 0 ? void 0 : _a.toggleStations) {
            navigate(locationState === null || locationState === void 0 ? void 0 : locationState.pathname, { replace: true, state: Object.assign(Object.assign({}, locationState === null || locationState === void 0 ? void 0 : locationState.state), { toggleStations: false }) });
        }
    };
    const stationSelection = (id) => {
        setSelectedStation(id);
    };
    return (React.createElement("div", { className: classes.station },
        React.createElement("div", { className: classes.back, onClick: hideStations },
            React.createElement(ArrowLeftIcon, null)),
        React.createElement("div", { className: classes.title }, intl.formatMessage({ id: 'officeTrips.choosePlace', defaultMessage: 'Оберіть місце очікування' })),
        React.createElement("div", { className: classes.list },
            error && React.createElement(Alert, { message: intl.formatMessage({ id: 'error.dataLoading', defaultMessage: 'Ошибка при загрузке данных' }), type: "error" }),
            loading && React.createElement(Preloader, { inner: true }), listStations === null || listStations === void 0 ? void 0 :
            listStations.map((station) => (React.createElement(StationItem, { key: station.id, onClick: () => { stationSelection(station === null || station === void 0 ? void 0 : station.id); }, station: station, selectedStation: selectedStation })))),
        React.createElement("div", { className: globalClasses.controlsFixed },
            React.createElement(BtnBlue, { onClick: setStatusWaiting, fullWidth: true, text: intl.formatMessage({ id: 'common.confirm', defaultMessage: 'Підтвердити' }) }))));
};
export default StationSelection;
