import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Auth from '@/components/Auth/Auth';
import Login from '@/components/Auth/Login/Login';
import Registration from '@/components/Auth/Registration/Registration';
import ResetPassword from '@/components/Auth/ResetPassword/ResetPassword';
import Blocked from '@/components/Blocked/Blocked';
import ErrorPage from '@/components/ErrorPage/ErrorPage';
import { routes } from '@/utils/constants';
const PublicRoutes = ({ isLoginAndBlocked }) => {
    return (React.createElement(Routes, null,
        React.createElement(Route, { path: routes.auth.f, element: React.createElement(Auth, null) }),
        React.createElement(Route, { path: routes.login.f, element: React.createElement(Login, null) }),
        React.createElement(Route, { path: routes.registration.f, element: React.createElement(Registration, null) }),
        React.createElement(Route, { path: routes.resetPassword.f, element: React.createElement(ResetPassword, null) }),
        React.createElement(Route, { path: routes.errorPage.f, element: React.createElement(ErrorPage, null) }),
        isLoginAndBlocked
            ? (React.createElement(React.Fragment, null,
                React.createElement(Route, { path: routes.blocked.f, element: React.createElement(Blocked, null) }),
                React.createElement(Route, { path: "/", element: React.createElement(Navigate, { to: routes.blocked.f }) }),
                React.createElement(Route, { path: "*", element: React.createElement(Navigate, { to: routes.blocked.f }) })))
            : (React.createElement(React.Fragment, null,
                React.createElement(Route, { path: "/", element: React.createElement(Navigate, { to: routes.auth.f }) }),
                React.createElement(Route, { path: "*", element: React.createElement(Navigate, { to: routes.auth.f }) })))));
};
export default PublicRoutes;
