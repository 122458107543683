export default {
    selectedCountryAriaLabel: 'Кишвари интихобшуда',
    noCountrySelected: 'Кишвар интихоб нашудааст',
    countryListAriaLabel: 'Рӯйхати кишварҳо',
    searchPlaceholder: 'Ҷустуҷӯ',
    zeroSearchResults: 'Натиҷаҳо ёфт нашуданд',
    oneSearchResult: '1 натиҷа ёфт шуд',
    ac: 'Ҷазираи Воснесенская',
    xk: 'Косово'
};
