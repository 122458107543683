var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Upload, message } from 'antd';
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import classes from './UploadFormFiles.module.scss';
import client from '@/graphql/client';
import { GET_SIGNED_URL } from '@/graphql/file';
import DeleteIcon from '@/icons/delete.svg';
import PlusIcon from '@/icons/plus.svg';
import ReceiptIcon from '@/icons/receipt-icon.svg';
import { formatFileSize } from '@/utils/helpers';
const UploadFormFiles = ({ intl, isSingle, fileList, setFileList }) => {
    const [headers, setHeaders] = useState();
    const customRequest = (_a) => __awaiter(void 0, [_a], void 0, function* ({ file, onSuccess }) {
        const isLt2M = file.size / 1024 / 1024 < 20;
        const isJpgOrPng = (file === null || file === void 0 ? void 0 : file.type) === 'image/jpeg' || (file === null || file === void 0 ? void 0 : file.type) === 'image/png';
        if (!isJpgOrPng) {
            void message.error(intl.formatMessage({ id: 'common.fileValidation', defaultMessage: 'Вы можете загружать только файлы JPG/PNG!' }));
            onSuccess && onSuccess(null);
            return;
        }
        if (!isLt2M) {
            void message.error(intl.formatMessage({ id: 'common.fileSizeValidation', defaultMessage: 'Размер файла не должен превышать 20MB!' }));
            onSuccess && onSuccess(null);
            return;
        }
        setHeaders({
            'x-amz-acl': 'public-read',
            'Content-Type': file === null || file === void 0 ? void 0 : file.type
        });
        try {
            const { data: { getSignedUrl } } = yield client.query({
                query: GET_SIGNED_URL,
                variables: {
                    getSignedUrlInput: {
                        filename: file === null || file === void 0 ? void 0 : file.name,
                        contentType: file.type
                    }
                }
            });
            const url = getSignedUrl === null || getSignedUrl === void 0 ? void 0 : getSignedUrl.signedUrl;
            const xhr = new XMLHttpRequest();
            xhr.open('PUT', url);
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        void message.success(intl.formatMessage({ id: 'common.fileDownloaded', defaultMessage: 'Файл загружен!' }));
                        setFileList((prevFileList) => [
                            ...prevFileList,
                            {
                                docKey: getSignedUrl === null || getSignedUrl === void 0 ? void 0 : getSignedUrl.fileKey,
                                signedUrl: getSignedUrl === null || getSignedUrl === void 0 ? void 0 : getSignedUrl.signedUrl,
                                mimeType: file === null || file === void 0 ? void 0 : file.type,
                                filename: file === null || file === void 0 ? void 0 : file.name,
                                name: file === null || file === void 0 ? void 0 : file.name,
                                file
                            }
                        ]);
                        onSuccess && onSuccess(null);
                    }
                    else {
                        void message.error(intl.formatMessage({ id: 'common.fileDownloadedError', defaultMessage: 'Ошибка при загрузке файла' }));
                        onSuccess && onSuccess(null);
                    }
                }
            };
            xhr.send(file);
        }
        catch (error) {
            void message.error(intl.formatMessage({ id: 'common.fileDownloadedError', defaultMessage: 'Ошибка при загрузке файла' }));
            onSuccess && onSuccess(null);
        }
    });
    const handleDelete = (file) => { setFileList((prevFileList) => prevFileList === null || prevFileList === void 0 ? void 0 : prevFileList.filter((item) => (item === null || item === void 0 ? void 0 : item.uid) !== (file === null || file === void 0 ? void 0 : file.uid))); };
    const renderUploadButton = (React.createElement("div", { className: classes.uploadButton },
        React.createElement(PlusIcon, null),
        intl.formatMessage({ id: 'common.addPhoto', defaultMessage: 'Додати фото' })));
    const acceptFiles = '.jpg,.jpeg,.png';
    return (React.createElement("div", { className: classes.uploadFiles },
        React.createElement(Upload, { accept: acceptFiles, headers: headers, fileList: fileList, customRequest: customRequest, itemRender: (originNode, file) => {
                var _a;
                if (!(file === null || file === void 0 ? void 0 : file.mimeType))
                    return null;
                const size = (_a = file === null || file === void 0 ? void 0 : file.file) === null || _a === void 0 ? void 0 : _a.size;
                return (React.createElement("div", { className: classes.fileItem },
                    React.createElement(ReceiptIcon, { width: 48, height: 48 }),
                    React.createElement("div", { className: classes.fileItemInfo },
                        React.createElement("div", { className: classes.fileItemName }, file === null || file === void 0 ? void 0 : file.name),
                        React.createElement("div", { className: classes.fileItemSize }, formatFileSize(size))),
                    React.createElement("div", { className: classes.delete, onClick: () => { handleDelete(file); } },
                        React.createElement(DeleteIcon, { width: 20, height: 20 }))));
            } },
            !isSingle && renderUploadButton,
            isSingle && (fileList === null || fileList === void 0 ? void 0 : fileList.length) === 0 && renderUploadButton)));
};
export default injectIntl(UploadFormFiles);
