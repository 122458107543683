import React from 'react';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import classes from './Auth.module.scss';
import BtnBlue from '@/atoms/BtnBlue/BtnBlue';
import login from '@/images/login.jpg';
import { routes } from '@/utils/constants';
const Auth = () => {
    const intl = useIntl();
    return (React.createElement("div", { className: classes.loginHolder },
        React.createElement("div", { className: classes.img },
            React.createElement("img", { src: login, width: 393, height: 527, alt: "login" })),
        React.createElement("div", { className: classes.box },
            React.createElement("div", { className: classes.titleAuth }, intl.formatMessage({ id: 'common.authTitle', defaultMessage: 'Працюйте у найвідоміших службах через єдиний додаток' })),
            React.createElement(NavLink, { to: routes.login.f },
                React.createElement(BtnBlue, { text: intl.formatMessage({ id: 'common.signIn', defaultMessage: 'Увійти' }), fullWidth: true, high: true })),
            process.env.SITE_HOST === 'localhost' && (React.createElement("div", { className: classes.row },
                React.createElement(NavLink, { to: routes.registration.f, className: classes.link }, intl.formatMessage({ id: 'common.registration', defaultMessage: 'Регистрация' })))))));
};
export default Auth;
