import { useQuery } from '@apollo/client';
import { LIST_SCHEDULE } from '@/graphql/cabinet';
const useListSchedule = ({ limit = 5, filters, ordering }) => {
    const { beforeDate, sinceDate } = filters !== null && filters !== void 0 ? filters : {};
    const { start, end } = ordering !== null && ordering !== void 0 ? ordering : {};
    const { loading, error, data } = useQuery(LIST_SCHEDULE, {
        variables: {
            listScheduleInput: Object.assign(Object.assign({ limit }, ordering && {
                ordering: {
                    end,
                    start
                }
            }), filters && {
                filters: {
                    beforeDate,
                    sinceDate
                }
            })
        }
    });
    return {
        listScheduleData: data,
        listScheduleLoading: loading,
        listScheduleError: error
    };
};
export default useListSchedule;
