var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AutoComplete, Form, message } from 'antd';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import client from '@/graphql/client';
import { TRIPS_STATIONS_AUTOCOMPLETE } from '@/graphql/officeTrips';
const fetchUserList = (val, intl) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const { data: { tripsStationsAutocomplete: { items } } } = yield client.query({
            query: TRIPS_STATIONS_AUTOCOMPLETE,
            variables: {
                tripsStationsAutocompleteInput: {
                    filters: Object.assign({}, (val && { query: val })),
                    limit: 5
                }
            },
            fetchPolicy: 'network-only'
        });
        return (items === null || items === void 0 ? void 0 : items.map((item) => ({
            label: `${item === null || item === void 0 ? void 0 : item.address} - ${item === null || item === void 0 ? void 0 : item.name}`,
            value: item.id
        }))) || [];
    }
    catch (error) {
        void message.error(intl.formatMessage({ id: 'error.error', defaultMessage: 'Произошла ошибка' }), 3);
        return [];
    }
});
const StationsSelect = memo(({ forForm, addressIds, isRequired, disabled, setAddressIds, name, label, form, message }) => {
    var _a;
    const [options, setOptions] = useState([]);
    const intl = useIntl();
    const fetchInitialOptions = () => __awaiter(void 0, void 0, void 0, function* () {
        const newOptions = yield fetchUserList('', intl);
        setOptions(newOptions);
    });
    useEffect(() => {
        void fetchInitialOptions();
    }, []);
    const handleSearch = useCallback((value) => __awaiter(void 0, void 0, void 0, function* () {
        if (!value)
            return;
        const newOptions = yield fetchUserList(value.trim(), intl);
        setOptions(newOptions);
    }), []);
    const component = (React.createElement(AutoComplete, { allowClear: true, options: options, value: addressIds === null || addressIds === void 0 ? void 0 : addressIds.label, onSearch: handleSearch, disabled: disabled, onFocus: (e) => {
            var _a, _b;
            if (!((_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value) || ((_b = e === null || e === void 0 ? void 0 : e.target) === null || _b === void 0 ? void 0 : _b.value) === '') {
                void fetchInitialOptions();
            }
        }, onChange: (value) => {
            const selectedOption = options === null || options === void 0 ? void 0 : options.find((option) => (option === null || option === void 0 ? void 0 : option.value) === value);
            setAddressIds(selectedOption !== null && selectedOption !== void 0 ? selectedOption : { label: value, value });
            name && form.setFieldsValue({ [name]: selectedOption ? selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.label : value });
        }, placeholder: intl.formatMessage({ id: 'common.enterAddress', defaultMessage: 'Введите адрес' }) }));
    return forForm
        ? React.createElement(Form.Item, { name: name, label: label, initialValue: (_a = addressIds === null || addressIds === void 0 ? void 0 : addressIds.label) !== null && _a !== void 0 ? _a : '', rules: [{ required: isRequired, message: message !== null && message !== void 0 ? message : intl.formatMessage({ id: 'common.enterAddress', defaultMessage: 'Введите адрес' }) }] }, component)
        : component;
});
StationsSelect.displayName = 'StationsSelect';
StationsSelect.propTypes = {
    addressIds: PropTypes.any,
    setAddressIds: PropTypes.func.isRequired,
    forForm: PropTypes.bool,
    isRequired: PropTypes.bool,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    label: PropTypes.string,
    message: PropTypes.string,
    form: PropTypes.any
};
export default StationsSelect;
