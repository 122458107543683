export default {
    selectedCountryAriaLabel: 'Selected country',
    noCountrySelected: 'No country selected',
    countryListAriaLabel: 'List of countries',
    searchPlaceholder: 'Search',
    zeroSearchResults: 'No results found',
    oneSearchResult: '1 result found',
    ac: 'Ascension Island',
    xk: 'Kosovo'
};
