import React from 'react';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import classes from './ErrorPage.module.scss';
import BtnBlue from '@/atoms/BtnBlue/BtnBlue';
import SadIcon from '@/icons/sad-smile.svg';
import { routes } from '@/utils/constants';
const ErrorPage = () => {
    const intl = useIntl();
    return (React.createElement("div", { className: classes.blocked },
        React.createElement("div", { className: classes.icon },
            React.createElement(SadIcon, null)),
        React.createElement("div", { className: classes.title }, "404"),
        React.createElement("div", { className: classes.description },
            intl.formatMessage({ id: 'error.errorPage', defaultMessage: 'К сожалению, запрашиваемая вами страница не существует. Возможно, она была удалена, перенесена или вы ввели неправильный адрес' }),
            "."),
        React.createElement(NavLink, { to: routes.auth.f, style: { width: '100%' } },
            React.createElement(BtnBlue, { text: intl.formatMessage({ id: 'common.toHome', defaultMessage: 'На главную' }), fullWidth: true, high: true }))));
};
export default ErrorPage;
