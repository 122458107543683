import { Menu } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import classes from './MenuItems.module.scss';
// import PersonalDataIcon from '@/icons/account-circle.svg'
import useAppSelector from '@/hooks/useAppSelector';
import AccountIcon from '@/icons/account.svg';
import OfficeTripsIcon from '@/icons/office-trips.svg';
import { routes } from '@/utils/constants';
const getItems = (officeTripsEnabled, intl) => {
    const items = [
        { key: 'account', label: React.createElement(NavLink, { to: routes.cabinet.f, className: classes.menuLink },
                React.createElement("div", { className: classes.menuIcon },
                    React.createElement(AccountIcon, null)),
                intl.formatMessage({ id: 'common.office', defaultMessage: 'Кабінет водія' })) }
    ];
    if (officeTripsEnabled) {
        items.push({
            key: 'officeTrips',
            label: React.createElement(NavLink, { to: routes.officeTrips.f, className: classes.menuLink },
                React.createElement("div", { className: classes.menuIcon },
                    React.createElement(OfficeTripsIcon, null)),
                "Office trips")
        });
    }
    return items;
};
const MenuItems = () => {
    // const onClick: MenuProps['onClick'] = (e) => {
    //   console.log('click ', e)
    // }
    const { officeTripsEnabled } = useAppSelector((state) => state.auth.settings);
    const intl = useIntl();
    const items = getItems(officeTripsEnabled, intl);
    return (React.createElement(Menu
    // onClick={onClick}
    , { 
        // onClick={onClick}
        defaultSelectedKeys: ['1'], defaultOpenKeys: ['sub1'], mode: "inline", items: items }));
};
export default MenuItems;
