import { useQuery } from '@apollo/client';
import { GET_CURRENT_EVENT } from '@/graphql/cabinet';
const useCurrentEvent = () => {
    const { loading, error, data } = useQuery(GET_CURRENT_EVENT);
    return {
        eventData: data,
        eventLoading: loading,
        eventError: error
    };
};
export default useCurrentEvent;
