import { useLazyQuery } from '@apollo/client';
import { Select } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import classes from './DriversForCarTransferAcceptance.module.scss';
import { renderNotFoundContent } from '../RenderNotFoundContent/RenderNotFoundContent';
import { LIST_SATISFYING_DRIVERS_FOR_CAR_TRANSFER_ACCEPTANCE } from '@/graphql/acts';
import MapPointIcon from '@/icons/map-point.svg';
const { Option } = Select;
const DriversForCarTransferAcceptance = ({ setSelectedDriver }) => {
    var _a, _b;
    const intl = useIntl();
    const [listSatisfyingDriversForCarTransferAcceptance, { loading, error, data }] = useLazyQuery(LIST_SATISFYING_DRIVERS_FOR_CAR_TRANSFER_ACCEPTANCE, {
        fetchPolicy: 'cache-first'
    });
    const driversOptions = (_b = (_a = data === null || data === void 0 ? void 0 : data.listSatisfyingDriversForCarTransferAcceptance) === null || _a === void 0 ? void 0 : _a.drivers.map((driver) => ({ label: `${driver === null || driver === void 0 ? void 0 : driver.firstName} ${driver === null || driver === void 0 ? void 0 : driver.lastName}`, avatarUrl: driver === null || driver === void 0 ? void 0 : driver.avatarUrl, value: driver === null || driver === void 0 ? void 0 : driver.id }))) !== null && _b !== void 0 ? _b : [];
    const driversWithFleetOptions = [{ label: `${intl.formatMessage({ id: 'common.park', defaultMessage: 'Автопарк' })}`, avatarUrl: null, value: 'fleet' }, ...driversOptions];
    return (React.createElement(Select, { optionFilterProp: "label", showAction: ['focus', 'click'], notFoundContent: renderNotFoundContent(loading, error, intl), onDropdownVisibleChange: (open) => (open) && listSatisfyingDriversForCarTransferAcceptance(), onChange: (value) => { setSelectedDriver(value); }, placeholder: intl.formatMessage({ id: 'common.chooseRecipient', defaultMessage: 'Оберіть отримувача' }), filterOption: (input, option) => { var _a, _b; return ((_b = (_a = option === null || option === void 0 ? void 0 : option.label) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === null || _b === void 0 ? void 0 : _b.indexOf(input === null || input === void 0 ? void 0 : input.toLowerCase())) >= 0; }, dropdownRender: (menu) => (React.createElement("div", { className: classes.dropdown }, menu)) }, driversWithFleetOptions.map((person) => (React.createElement(Option, { key: person.value, value: person.value },
        React.createElement("div", { className: `${classes.option} ${person.value === 'fleet' ? classes.optionFleet : ''}` },
            React.createElement("div", { className: classes.ava }, person.value === 'fleet'
                ? (React.createElement(MapPointIcon, null))
                : ((person === null || person === void 0 ? void 0 : person.avatarUrl) && React.createElement("img", { alt: "avatar", src: person === null || person === void 0 ? void 0 : person.avatarUrl }))),
            React.createElement("div", { className: classes.box },
                React.createElement("div", { className: classes.label }, person === null || person === void 0 ? void 0 : person.label))))))));
};
export default DriversForCarTransferAcceptance;
