import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
// import Cars from '@/components/Cars/Cars'
import CarTransferProfile from '@/components/Acts/CarTransferProfile/CarTransferProfile';
import ReturnCar from '@/components/Acts/ReturnCar/ReturnCar';
import Cabinet from '@/components/Cabinet/Cabinet';
import Header from '@/components/Header/Header';
import OfficeTrip from '@/components/OfficeTrip/OfficeTrip';
import CreateOfficeTrips from '@/components/OfficeTrips/CreateOfficeTrips/CreateOfficeTrips';
import OfficeTrips from '@/components/OfficeTrips/OfficeTrips';
import classes from '@/containers/App/Global.module.scss';
import { routes } from '@/utils/constants';
const ProtectedRoutes = () => {
    const location = useLocation();
    const hideHeaderPaths = [`${routes.carTransfer.f}/:id`, routes.createOfficeTrips.f, routes.returnCar.f];
    const shouldHideHeader = hideHeaderPaths.some((path) => new RegExp(`^${path.replace(/:[^\s/]+/g, '([\\w-]+)')}$`).test(location.pathname));
    return (React.createElement("div", { className: classes.wrapper },
        !shouldHideHeader && React.createElement(Header, null),
        React.createElement(Routes, null,
            React.createElement(Route, { path: routes.cabinet.f, element: React.createElement(Cabinet, null) }),
            React.createElement(Route, { path: routes.officeTrips.f, element: React.createElement(OfficeTrips, null) }),
            React.createElement(Route, { path: routes.createOfficeTrips.f, element: React.createElement(CreateOfficeTrips, null) }),
            React.createElement(Route, { path: `${routes.carTransfer.f}/:id`, element: React.createElement(CarTransferProfile, null) }),
            React.createElement(Route, { path: routes.returnCar.f, element: React.createElement(ReturnCar, null) }),
            React.createElement(Route, { path: "/", element: React.createElement(Navigate, { to: routes.cabinet.f }) }),
            React.createElement(Route, { path: "*", element: React.createElement(Navigate, { to: routes.cabinet.f }) })),
        React.createElement(OfficeTrip, null)));
};
export default ProtectedRoutes;
