export default {
    selectedCountryAriaLabel: 'Вибрана країна',
    noCountrySelected: 'Країну не вибрано',
    countryListAriaLabel: 'Список країн',
    searchPlaceholder: 'Пошук',
    zeroSearchResults: 'Результатів не знайдено',
    oneSearchResult: 'Знайдено 1 результат',
    ac: 'Острів Вознесіння',
    xk: 'Косово'
};
