import Hotjar from '@hotjar/browser';
import React from 'react';
import { createRoot } from 'react-dom/client';
import Root from './containers/Root/Root';
if (process.env.HOTJAR_SV && process.env.YOUR_HOTJAR_ID && process.env.API_HOST === 'dbo-api.mytaxicrm.com/graphql') {
    Hotjar.init(+process.env.YOUR_HOTJAR_ID, +process.env.HOTJAR_SV);
}
const rootElement = document.getElementById('root');
if (rootElement != null) {
    createRoot(rootElement).render(React.createElement(React.StrictMode, null,
        React.createElement(Root, null)));
}
else {
    throw new Error('Root element not found in the document.');
}
