export default {
    ad: 'Андорра',
    ae: 'Амороти Муттаҳидаи Араб',
    af: 'Афғонистон',
    ag: 'Антигуа ва Барбуда',
    ai: 'Ангилия',
    al: 'Албания',
    am: 'Арманистон',
    ao: 'Ангола',
    aq: 'Антарктида',
    ar: 'Аргентина',
    as: 'Самоаи Амрико',
    at: 'Австрия',
    au: 'Австралия',
    aw: 'Аруба',
    ax: 'Ҷазираҳои Оланд',
    az: 'Озарбойҷон',
    ba: 'Босния ва Ҳерсеговина',
    bb: 'Барбадос',
    bd: 'Бангладеш',
    be: 'Белгия',
    bf: 'Буркина-Фасо',
    bg: 'Булғория',
    bh: 'Баҳрайн',
    bi: 'Бурунди',
    bj: 'Бенин',
    bl: 'Сен-Бартелеми',
    bm: 'Бермуд',
    bn: 'Бруней',
    bo: 'Боливия',
    bq: 'Нидерландҳои Кариб',
    br: 'Бразилия',
    bs: 'Багама',
    bt: 'Бутан',
    bv: 'Ҷазираи Буве',
    bw: 'Ботсвана',
    by: 'Белорус',
    bz: 'Белиз',
    ca: 'Канада',
    cc: 'Ҷазираҳои Кокос',
    cd: 'Конго - Киншаса',
    cf: 'Ҷумҳурии Африқои Марказӣ',
    cg: 'Конго - Браззавил',
    ch: 'Швейтсария',
    ci: 'Кот-д’Ивуар',
    ck: 'Ҷазираҳои Кук',
    cl: 'Чили',
    cm: 'Камерун',
    cn: 'Чин',
    co: 'Колумбия',
    cr: 'Коста-Рика',
    cu: 'Куба',
    cv: 'Кабо-Верде',
    cw: 'Кюрасао',
    cx: 'Ҷазираи Мавлуд',
    cy: 'Кипр',
    cz: 'Ҷумҳурии Чех',
    de: 'Олмон',
    dj: 'Ҷибутӣ',
    dk: 'Дания',
    dm: 'Доминика',
    do: 'Ҷумҳурии Доминикан',
    dz: 'Алҷазоир',
    ec: 'Эквадор',
    ee: 'Эстония',
    eg: 'Миср',
    eh: 'Саҳрои Ғарбӣ',
    er: 'Эритрея',
    es: 'Испания',
    et: 'Эфиопия',
    fi: 'Финляндия',
    fj: 'Фиҷи',
    fk: 'Ҷазираҳои Фолкланд',
    fm: 'Микронезия',
    fo: 'Ҷазираҳои Фарер',
    fr: 'Фаронса',
    ga: 'Габон',
    gb: 'Подшоҳии Муттаҳида',
    gd: 'Гренада',
    ge: 'Гурҷистон',
    gf: 'Гвианаи Фаронса',
    gg: 'Гернси',
    gh: 'Гана',
    gi: 'Гибралтар',
    gl: 'Гренландия',
    gm: 'Гамбия',
    gn: 'Гвинея',
    gp: 'Гваделупа',
    gq: 'Гвинеяи Экваторӣ',
    gr: 'Юнон',
    gs: 'Ҷорҷияи Ҷанубӣ ва Ҷазираҳои Сандвичи Ҷанубӣ',
    gt: 'Гватемала',
    gu: 'Гуам',
    gw: 'Гвинея-Бисау',
    gy: 'Гайана',
    hk: 'Ҳонконг (Ҳукумати махсуси маъмурии Чин)',
    hm: 'Ҷазираҳои Ҳерд ва Макдоналд',
    hn: 'Ҳондурас',
    hr: 'Хорватия',
    ht: 'Ҳаитӣ',
    hu: 'Маҷористон',
    id: 'Индонезия',
    ie: 'Ирландия',
    il: 'Исроил',
    im: 'Ҷазираи Ман',
    in: 'Ҳиндустон',
    io: 'Мавзеъи Бритониё дар Уқёнуси Ҳинд',
    iq: 'Ироқ',
    ir: 'Эрон',
    is: 'Исландия',
    it: 'Италия',
    je: 'Ҷерсӣ',
    jm: 'Ямайка',
    jo: 'Урдун',
    jp: 'Ҷопон',
    ke: 'Кения',
    kg: 'Қирғизистон',
    kh: 'Камбоджа',
    ki: 'Кирибатӣ',
    km: 'Комор',
    kn: 'Сент-Китс ва Невис',
    kp: 'Кореяи Шимолӣ',
    kr: 'Кореяи Ҷанубӣ',
    kw: 'Кувайт',
    ky: 'Ҷазираҳои Кайман',
    kz: 'Қазоқистон',
    la: 'Лаос',
    lb: 'Лубнон',
    lc: 'Сент-Люсия',
    li: 'Лихтенштейн',
    lk: 'Шри-Ланка',
    lr: 'Либерия',
    ls: 'Лесото',
    lt: 'Литва',
    lu: 'Люксембург',
    lv: 'Латвия',
    ly: 'Либия',
    ma: 'Марокаш',
    mc: 'Монако',
    md: 'Молдова',
    me: 'Черногория',
    mf: 'Сен-Мартин',
    mg: 'Мадагаскар',
    mh: 'Ҷазираҳои Маршалл',
    mk: 'Македонияи Шимолӣ',
    ml: 'Малӣ',
    mm: 'Мянма (Бирма)',
    mn: 'Муғулистон',
    mo: 'Макао (Ҳукумати махсуси маъмурии Чин)',
    mp: 'Ҷазираҳои Марианаи Шимолӣ',
    mq: 'Мартиника',
    mr: 'Мавритания',
    ms: 'Монтсеррат',
    mt: 'Малта',
    mu: 'Маврикий',
    mv: 'Малдивҳо',
    mw: 'Малави',
    mx: 'Мексика',
    my: 'Малайзия',
    mz: 'Мозамбик',
    na: 'Намибия',
    nc: 'Каледонияи Нав',
    ne: 'Нигер',
    nf: 'Ҷазираи Норфолк',
    ng: 'Нигерия',
    ni: 'Никарагуа',
    nl: 'Нидерланд',
    no: 'Норвегия',
    np: 'Непал',
    nr: 'Науру',
    nu: 'Ниуе',
    nz: 'Зеландияи Нав',
    om: 'Умон',
    pa: 'Панама',
    pe: 'Перу',
    pf: 'Полинезияи Фаронса',
    pg: 'Папуа Гвинеяи Нав',
    ph: 'Филиппин',
    pk: 'Покистон',
    pl: 'Лаҳистон',
    pm: 'Сен-Пер ва Микелон',
    pn: 'Ҷазираҳои Питкэрн',
    pr: 'Пуэрто-Рико',
    ps: 'Минтақаҳои Фаластин',
    pt: 'Португалия',
    pw: 'Палау',
    py: 'Парагвай',
    qa: 'Қатар',
    re: 'Реюнион',
    ro: 'Руминия',
    rs: 'Сербия',
    ru: 'Русия',
    rw: 'Руанда',
    sa: 'Арабистони Саудӣ',
    sb: 'Ҷазираҳои Соломон',
    sc: 'Сейшел',
    sd: 'Судон',
    se: 'Шветсия',
    sg: 'Сингапур',
    sh: 'Ҷазираи Муқаддаси Елена',
    si: 'Словения',
    sj: 'Шпитсберген ва Ян-Майен',
    sk: 'Словакия',
    sl: 'Сиерра-Леоне',
    sm: 'Сан-Марино',
    sn: 'Сенегал',
    so: 'Сомалӣ',
    sr: 'Суринам',
    ss: 'Судони Ҷанубӣ',
    st: 'Сан-Томе ва Принсипи',
    sv: 'Салвадор',
    sx: 'Синт-Мартен',
    sy: 'Сурия',
    sz: 'Свазиленд',
    tc: 'Ҷазираҳои Туркс ва Кайкос',
    td: 'Чад',
    tf: 'Минтақаҳои Ҷанубии Фаронса',
    tg: 'Того',
    th: 'Тайланд',
    tj: 'Тоҷикистон',
    tk: 'Токелау',
    tl: 'Тимори Шарқӣ',
    tm: 'Туркманистон',
    tn: 'Тунис',
    to: 'Тонга',
    tr: 'Туркия',
    tt: 'Тринидад ва Тобаго',
    tv: 'Тувалу',
    tw: 'Тайван',
    tz: 'Танзания',
    ua: 'Украина',
    ug: 'Уганда',
    um: 'Хурдҷазираҳои дурдасти Иёлоти Муттаҳида',
    us: 'Иёлоти Муттаҳида',
    uy: 'Уругвай',
    uz: 'Ӯзбекистон',
    va: 'Шаҳри Ватикан',
    vc: 'Сент-Винсент ва Гренадин',
    ve: 'Венесуэла',
    vg: 'Ҷазираҳои Виргини Бритониё',
    vi: 'Ҷазираҳои Виргини Иёлоти Муттаҳида',
    vn: 'Ветнам',
    vu: 'Вануату',
    wf: 'Уоллис ва Футуна',
    ws: 'Самоа',
    ye: 'Яман',
    yt: 'Майотта',
    za: 'Африқои Ҷанубӣ',
    zm: 'Замбия',
    zw: 'Зимбабве'
};
