import React from 'react';
import { NavLink } from 'react-router-dom';
import classes from './Breadcrumb.module.scss';
import HomeIcon from '@/icons/home.svg';
import { routes } from '@/utils/constants';
const Breadcrumb = ({ items, black }) => {
    return (React.createElement("ul", { className: `${classes.list} ${black ? classes.black : ''}` }, items === null || items === void 0 ? void 0 : items.map((item) => (React.createElement("li", { key: item.path, className: classes.item },
        React.createElement(NavLink, { to: item.path, className: classes.link },
            item.path === routes.cabinet.f && React.createElement(HomeIcon, null),
            item.text))))));
};
export default Breadcrumb;
