var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import { Form, Input, Radio, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import classes from './ReturnCar.module.scss';
import Breadcrumb from '@/atoms/Breadcrumb/Breadcrumb';
import BtnBlue from '@/atoms/BtnBlue/BtnBlue';
import DriversForCarTransferAcceptance from '@/atoms/DriversForCarTransferAcceptance/DriversForCarTransferAcceptance';
import UploadFormFiles from '@/atoms/UploadFormFiles/UploadFormFiles';
import globalClasses from '@/containers/App/Global.module.scss';
import { CREATE_CAR_TRANSFER_ACCEPTANCE } from '@/graphql/acts';
import ArrowLeftIcon from '@/icons/arrow-right.svg';
import { routes } from '@/utils/constants';
import { getErrorMessage } from '@/utils/validateMessages';
const steps = [
    { id: 1, textId: 'common.data', defaultMessage: 'Дані' },
    { id: 2, textId: 'common.photo', defaultMessage: 'Фото' },
    { id: 3, textId: 'common.damage', defaultMessage: 'Пошкодження' }
];
const ReturnCar = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const [form] = Form.useForm();
    const [step, setStep] = useState(1);
    const [submittable, setSubmittable] = React.useState(false);
    const [loadingState, setLoadingState] = useState(false);
    const [hasDefects, setHasDefects] = useState(false);
    const [selectedDriver, setSelectedDriver] = useState(null);
    const [defectsPhotosList, setDefectsPhotosList] = useState([]);
    const [bodyFrontPhotosList, setBodyFrontPhotosList] = useState([]);
    const [bodyBackPhotosList, setBodyBackPhotosList] = useState([]);
    const [bodyRightPhotosList, setBodyRightPhotosList] = useState([]);
    const [bodyLeftPhotosList, setBodyLeftPhotosList] = useState([]);
    const [cabinFrontPhotosList, setCabinFrontPhotosList] = useState([]);
    const [cabinBackPhotosList, setCabinBackPhotosList] = useState([]);
    const [dashboardPhotosList, setDashboardPhotosList] = useState([]);
    const [underHoodPhotosList, setUnderHoodPhotosList] = useState([]);
    const [truncPhotosList, setTruncPhotosList] = useState([]);
    const intl = useIntl();
    const navigate = useNavigate();
    const values = Form.useWatch([], form);
    const locationState = useLocation().state;
    const activeCarTransferAcceptance = locationState === null || locationState === void 0 ? void 0 : locationState.activeCarTransferAcceptance;
    useEffect(() => {
        if (step === 1) {
            form
                .validateFields(['odometer', 'technicalPassport', 'oilLevel', 'coolingLiquidLevel'])
                .then(() => { selectedDriver ? setSubmittable(true) : selectedDriver ? setSubmittable(true) : setSubmittable(false); })
                .catch(() => { setSubmittable(false); });
        }
        if (step === 3) {
            form
                .validateFields(['defects'])
                .then(() => { setSubmittable(true); })
                .catch(() => { setSubmittable(false); });
        }
    }, [form, values, selectedDriver]);
    const areAllPhotosFilled = () => {
        const arrays = [
            bodyFrontPhotosList,
            bodyBackPhotosList,
            bodyRightPhotosList,
            bodyLeftPhotosList,
            cabinFrontPhotosList,
            cabinBackPhotosList,
            dashboardPhotosList,
            underHoodPhotosList,
            truncPhotosList
        ];
        return arrays.every(array => array.length > 0);
    };
    const nextStep = () => {
        setStep((prevStep) => prevStep + 1);
        setSubmittable(false);
    };
    useEffect(() => {
        if (!locationState) {
            navigate(routes.cabinet.f);
        }
    }, []);
    const [createCarTransferAcceptance] = useMutation(CREATE_CAR_TRANSFER_ACCEPTANCE);
    const handleHasDefects = (e) => {
        if (e.target.value === 'YES') {
            setHasDefects(true);
        }
        else {
            setHasDefects(false);
        }
    };
    const handleClick = () => { navigate(-1); };
    const createCarTransferAcceptanceMutation = (createCarTransferAcceptanceInput) => {
        createCarTransferAcceptance({
            variables: { createCarTransferAcceptanceInput }
        }).then(() => {
            void message.success(intl.formatMessage({ id: 'common.actCreated', defaultMessage: 'Акт створений' }), 3);
            form.resetFields();
            setDefectsPhotosList([]);
            setBodyFrontPhotosList([]);
            setBodyBackPhotosList([]);
            setBodyRightPhotosList([]);
            setBodyLeftPhotosList([]);
            setCabinFrontPhotosList([]);
            setCabinBackPhotosList([]);
            setDashboardPhotosList([]);
            setUnderHoodPhotosList([]);
            setTruncPhotosList([]);
            setLoadingState(false);
            navigate(routes.cabinet.f);
        }, (err) => {
            getErrorMessage(err, intl);
            setLoadingState(false);
        });
    };
    const handleSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        setLoadingState(true);
        const { odometer, defects, coolingLiquidLevel, oilLevel, technicalPassport } = values;
        const createCarTransferAcceptanceInput = Object.assign(Object.assign(Object.assign({ returnToPark: selectedDriver === 'fleet', technicalPassport,
            coolingLiquidLevel,
            oilLevel, odometer: +odometer, defects }, selectedDriver !== 'fleet' && selectedDriver && { driverId: selectedDriver }), defectsPhotosList.length > 0 && hasDefects && { defectsPhotos: defectsPhotosList === null || defectsPhotosList === void 0 ? void 0 : defectsPhotosList.map(({ docKey, mimeType, filename }) => ({ docKey, mimeType, filename })) }), { photos: {
                bodyFront: bodyFrontPhotosList === null || bodyFrontPhotosList === void 0 ? void 0 : bodyFrontPhotosList.map(({ docKey, mimeType, filename }) => ({ docKey, mimeType, filename }))[0],
                bodyBack: bodyBackPhotosList === null || bodyBackPhotosList === void 0 ? void 0 : bodyBackPhotosList.map(({ docKey, mimeType, filename }) => ({ docKey, mimeType, filename }))[0],
                bodyRight: bodyRightPhotosList === null || bodyRightPhotosList === void 0 ? void 0 : bodyRightPhotosList.map(({ docKey, mimeType, filename }) => ({ docKey, mimeType, filename }))[0],
                bodyLeft: bodyLeftPhotosList === null || bodyLeftPhotosList === void 0 ? void 0 : bodyLeftPhotosList.map(({ docKey, mimeType, filename }) => ({ docKey, mimeType, filename }))[0],
                cabinFront: cabinFrontPhotosList === null || cabinFrontPhotosList === void 0 ? void 0 : cabinFrontPhotosList.map(({ docKey, mimeType, filename }) => ({ docKey, mimeType, filename }))[0],
                cabinBack: cabinBackPhotosList === null || cabinBackPhotosList === void 0 ? void 0 : cabinBackPhotosList.map(({ docKey, mimeType, filename }) => ({ docKey, mimeType, filename }))[0],
                dashboard: dashboardPhotosList === null || dashboardPhotosList === void 0 ? void 0 : dashboardPhotosList.map(({ docKey, mimeType, filename }) => ({ docKey, mimeType, filename }))[0],
                underHood: underHoodPhotosList === null || underHoodPhotosList === void 0 ? void 0 : underHoodPhotosList.map(({ docKey, mimeType, filename }) => ({ docKey, mimeType, filename }))[0],
                trunc: truncPhotosList === null || truncPhotosList === void 0 ? void 0 : truncPhotosList.map(({ docKey, mimeType, filename }) => ({ docKey, mimeType, filename }))[0]
            } });
        createCarTransferAcceptanceMutation(createCarTransferAcceptanceInput);
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classes.returnAct },
            React.createElement("div", { className: globalClasses.container },
                React.createElement("div", { className: globalClasses.back, onClick: handleClick },
                    React.createElement(ArrowLeftIcon, null)),
                React.createElement(Breadcrumb, { black: true, items: [{
                            id: 'home',
                            path: routes.cabinet.f,
                            text: intl.formatMessage({ id: 'common.toHome', defaultMessage: 'На головну' })
                        }] }),
                React.createElement("div", { className: globalClasses.innerPageTitle }, intl.formatMessage({ id: 'common.bringBackCar', defaultMessage: 'Здати авто' })),
                React.createElement("div", { className: globalClasses.box },
                    React.createElement("div", { className: globalClasses.boxTitle }, intl.formatMessage({ id: 'common.actReturnCar', defaultMessage: 'Акт на здачу авто' })),
                    React.createElement("div", { className: classes.car },
                        React.createElement("div", { className: classes.carImg }, ((_a = activeCarTransferAcceptance === null || activeCarTransferAcceptance === void 0 ? void 0 : activeCarTransferAcceptance.car) === null || _a === void 0 ? void 0 : _a.photoUrl) && React.createElement("img", { alt: "avatar", src: (_b = activeCarTransferAcceptance === null || activeCarTransferAcceptance === void 0 ? void 0 : activeCarTransferAcceptance.car) === null || _b === void 0 ? void 0 : _b.photoUrl })),
                        React.createElement("div", { className: classes.carBox },
                            React.createElement("div", { className: classes.carLicensePlate }, ((_c = activeCarTransferAcceptance === null || activeCarTransferAcceptance === void 0 ? void 0 : activeCarTransferAcceptance.car) === null || _c === void 0 ? void 0 : _c.licensePlate) || '—'),
                            React.createElement("div", { className: classes.brand }, ((_d = activeCarTransferAcceptance === null || activeCarTransferAcceptance === void 0 ? void 0 : activeCarTransferAcceptance.car) === null || _d === void 0 ? void 0 : _d.model) || '—'))),
                    React.createElement("div", { className: classes.boxInner },
                        React.createElement("div", { className: classes.boxInnerTitle }, intl.formatMessage({ id: 'acts.givesCar', defaultMessage: 'Віддає авто' })),
                        React.createElement("div", { className: classes.user },
                            React.createElement("div", { className: classes.userName }, (activeCarTransferAcceptance === null || activeCarTransferAcceptance === void 0 ? void 0 : activeCarTransferAcceptance.targetUser)
                                ? `${(_f = (_e = activeCarTransferAcceptance === null || activeCarTransferAcceptance === void 0 ? void 0 : activeCarTransferAcceptance.targetUser) === null || _e === void 0 ? void 0 : _e.firstName) === null || _f === void 0 ? void 0 : _f.charAt(0)}. ${(_h = (_g = activeCarTransferAcceptance === null || activeCarTransferAcceptance === void 0 ? void 0 : activeCarTransferAcceptance.targetUser) === null || _g === void 0 ? void 0 : _g.lastName) === null || _h === void 0 ? void 0 : _h.charAt(0)}.`
                                : `${(_j = activeCarTransferAcceptance === null || activeCarTransferAcceptance === void 0 ? void 0 : activeCarTransferAcceptance.targetDriver) === null || _j === void 0 ? void 0 : _j.firstName} ${(_k = activeCarTransferAcceptance === null || activeCarTransferAcceptance === void 0 ? void 0 : activeCarTransferAcceptance.targetDriver) === null || _k === void 0 ? void 0 : _k.lastName}`),
                            React.createElement("div", { className: classes.userPosition }, (activeCarTransferAcceptance === null || activeCarTransferAcceptance === void 0 ? void 0 : activeCarTransferAcceptance.targetUser)
                                ? intl.formatMessage({ id: 'common.manager', defaultMessage: 'Менеджер' })
                                : intl.formatMessage({ id: 'common.driver', defaultMessage: 'Водій' }))),
                        React.createElement("div", { className: classes.boxInnerTitle }, intl.formatMessage({ id: 'acts.acceptsCar', defaultMessage: 'Приймає авто' })),
                        React.createElement("div", { className: classes.user },
                            React.createElement("div", { className: classes.userPosition },
                                React.createElement(DriversForCarTransferAcceptance, { setSelectedDriver: setSelectedDriver }))))),
                React.createElement("div", { className: globalClasses.box },
                    React.createElement("div", { className: classes.returnTitle }, intl.formatMessage({ id: 'common.actReturnCar', defaultMessage: 'Акт на здачу авто' })),
                    React.createElement("div", { className: classes.stepsLine }, steps.map((stepInfo) => (React.createElement("div", { key: stepInfo.id, className: `${classes.stepsLineItem} 
                    ${step === stepInfo.id ? classes.stepsLineItemActive : ''} 
                    ${step > stepInfo.id ? classes.stepsLineItemComplete : ''}` },
                        React.createElement("div", { className: classes.stepsLineIcon }),
                        React.createElement("div", { className: classes.stepsLineText }, intl.formatMessage({ id: stepInfo.textId, defaultMessage: stepInfo.defaultMessage })))))),
                    React.createElement(Form, { form: form, autoComplete: "off", name: "returnCar", onFinish: handleSubmit },
                        React.createElement("div", { className: `${classes.step} ${step === 1 ? classes.stepActive : ''}` },
                            React.createElement(Form.Item, { name: "technicalPassport", rules: [{ required: true, message: '' }], label: intl.formatMessage({ id: 'common.vehicleTechnicalPassport', defaultMessage: 'Тех. паспорт на авто' }) },
                                React.createElement(Radio.Group, null,
                                    React.createElement(Radio, { value: "MISS" }, intl.formatMessage({ id: 'common.miss', defaultMessage: 'Відсутній' })),
                                    React.createElement(Radio, { value: "PRESENT" }, intl.formatMessage({ id: 'common.present', defaultMessage: 'Наявний' })))),
                            React.createElement(Form.Item, { name: "oilLevel", rules: [{ required: true, message: '' }], label: intl.formatMessage({ id: 'common.oilLevel', defaultMessage: 'Рівень мастила' }) },
                                React.createElement(Radio.Group, null,
                                    React.createElement(Radio, { value: "IN_NORM" }, intl.formatMessage({ id: 'common.inNorm', defaultMessage: 'У нормі' })),
                                    React.createElement(Radio, { value: "REFILL_REQUIRED" }, intl.formatMessage({ id: 'common.refill', defaultMessage: 'Потрібна заправка' })))),
                            React.createElement(Form.Item, { name: "coolingLiquidLevel", rules: [{ required: true, message: '' }], label: intl.formatMessage({ id: 'common.coolingLiquidLevel', defaultMessage: 'Рівень охолоджуючої рідини' }) },
                                React.createElement(Radio.Group, null,
                                    React.createElement(Radio, { value: "IN_NORM" }, intl.formatMessage({ id: 'common.inNorm', defaultMessage: 'У нормі' })),
                                    React.createElement(Radio, { value: "REFILL_REQUIRED" }, intl.formatMessage({ id: 'common.refill', defaultMessage: 'Потрібна заправка' })))),
                            React.createElement(Form.Item, { name: "odometer", normalize: (value) => (value ? value === null || value === void 0 ? void 0 : value.replace(/[^0-9]/g, '') : value), rules: [
                                    { required: true, message: '' },
                                    {
                                        validator: (_, value) => {
                                            if (value && parseInt(value, 10) > 99999999) {
                                                return Promise.reject(new Error(intl.formatMessage({ id: 'error.maxOdometer', defaultMessage: 'Максимальное значение 99999999 км' })));
                                            }
                                            return Promise.resolve();
                                        }
                                    }
                                ], label: intl.formatMessage({ id: 'common.odometer', defaultMessage: 'Пробіг' }) },
                                React.createElement(Input, { placeholder: 'km' })),
                            React.createElement("div", { className: globalClasses.controlsFixed },
                                React.createElement(BtnBlue, { onClick: nextStep, fullWidth: true, disabled: !submittable, text: intl.formatMessage({ id: 'common.next', defaultMessage: 'Далі' }) }))),
                        React.createElement("div", { className: `${classes.step} ${step === 2 ? classes.stepActive : ''}` },
                            React.createElement("div", { className: classes.photoTitle }, intl.formatMessage({ id: 'common.photoOutside', defaultMessage: 'Фото ззовні' })),
                            React.createElement(Form.Item, null,
                                React.createElement("div", { className: classes.labelGrey }, intl.formatMessage({ id: 'common.photo.front', defaultMessage: 'Спереду' })),
                                React.createElement(UploadFormFiles, { isSingle: true, fileList: bodyFrontPhotosList, setFileList: setBodyFrontPhotosList })),
                            React.createElement(Form.Item, null,
                                React.createElement("div", { className: classes.labelGrey }, intl.formatMessage({ id: 'common.photo.back', defaultMessage: 'Ззаду' })),
                                React.createElement(UploadFormFiles, { isSingle: true, fileList: bodyBackPhotosList, setFileList: setBodyBackPhotosList })),
                            React.createElement(Form.Item, null,
                                React.createElement("div", { className: classes.labelGrey }, intl.formatMessage({ id: 'common.photo.right', defaultMessage: 'З правого боку' })),
                                React.createElement(UploadFormFiles, { isSingle: true, fileList: bodyRightPhotosList, setFileList: setBodyRightPhotosList })),
                            React.createElement(Form.Item, null,
                                React.createElement("div", { className: classes.labelGrey }, intl.formatMessage({ id: 'common.photo.left', defaultMessage: 'З лівого боку' })),
                                React.createElement(UploadFormFiles, { isSingle: true, fileList: bodyLeftPhotosList, setFileList: setBodyLeftPhotosList })),
                            React.createElement("div", { className: classes.photoTitle }, intl.formatMessage({ id: 'common.photo.dashboard', defaultMessage: 'Панель приладів' })),
                            React.createElement(Form.Item, null,
                                React.createElement(UploadFormFiles, { isSingle: true, fileList: dashboardPhotosList, setFileList: setDashboardPhotosList })),
                            React.createElement("div", { className: classes.photoTitle }, intl.formatMessage({ id: 'common.photo.underHood', defaultMessage: 'Під капотом' })),
                            React.createElement(Form.Item, null,
                                React.createElement(UploadFormFiles, { isSingle: true, fileList: underHoodPhotosList, setFileList: setUnderHoodPhotosList })),
                            React.createElement("div", { className: classes.photoTitle }, intl.formatMessage({ id: 'common.photoInside', defaultMessage: 'Фото зсередини' })),
                            React.createElement(Form.Item, null,
                                React.createElement("div", { className: classes.labelGrey }, intl.formatMessage({ id: 'common.photo.salonFront', defaultMessage: 'Салон спереду' })),
                                React.createElement(UploadFormFiles, { isSingle: true, fileList: cabinFrontPhotosList, setFileList: setCabinFrontPhotosList })),
                            React.createElement(Form.Item, null,
                                React.createElement("div", { className: classes.labelGrey }, intl.formatMessage({ id: 'common.photo.salonBack', defaultMessage: 'Салон ззаду' })),
                                React.createElement(UploadFormFiles, { isSingle: true, fileList: cabinBackPhotosList, setFileList: setCabinBackPhotosList })),
                            React.createElement(Form.Item, null,
                                React.createElement("div", { className: classes.labelGrey }, intl.formatMessage({ id: 'common.photo.trunc', defaultMessage: 'Фото відкритого багажника' })),
                                React.createElement(UploadFormFiles, { isSingle: true, fileList: truncPhotosList, setFileList: setTruncPhotosList })),
                            React.createElement("div", { className: globalClasses.controlsFixed },
                                React.createElement(BtnBlue, { onClick: nextStep, fullWidth: true, disabled: !areAllPhotosFilled(), text: intl.formatMessage({ id: 'common.next', defaultMessage: 'Далі' }) }))),
                        React.createElement("div", { className: `${classes.step} ${step === 3 ? classes.stepActive : ''}` },
                            React.createElement(Form.Item, { name: "defects", rules: [{ required: true, message: '' }], label: intl.formatMessage({ id: 'common.damage', defaultMessage: 'Пошкодження' }) },
                                React.createElement(Radio.Group, { onChange: (e) => { handleHasDefects(e); } },
                                    React.createElement(Radio, { value: "NO" }, intl.formatMessage({ id: 'common.missing', defaultMessage: 'Відсутні' })),
                                    React.createElement(Radio, { value: "YES" }, intl.formatMessage({ id: 'common.available', defaultMessage: 'Наявні' })))),
                            hasDefects && (React.createElement(Form.Item, { name: "defectsPhotos" },
                                React.createElement(UploadFormFiles, { fileList: defectsPhotosList, setFileList: setDefectsPhotosList }))),
                            React.createElement("div", { className: globalClasses.controlsFixed },
                                React.createElement(BtnBlue, { fullWidth: true, type: "submit", disabled: !submittable, loading: loadingState, text: intl.formatMessage({ id: 'common.next', defaultMessage: 'Далі' }) })))))))));
};
export default ReturnCar;
