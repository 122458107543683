import { gql } from '@apollo/client';
export const GET_CURRENT_EVENT = gql `
  query GetCurrentEvent {
    getCurrentEvent {
      currentEvent {
        end
        eventType
        id
        start
        car {
          licensePlate
        }
      }
    }
  }
`;
export const LIST_SCHEDULE = gql `
  query ListSchedule($listScheduleInput: ListScheduleInput!) {
    listSchedule(listScheduleInput: $listScheduleInput) {
      items {
        id
        eventType
        start
        end
        car {
          licensePlate
        }
      }
    }
  }
`;
