import dayjs from 'dayjs';
import React from 'react';
import { useIntl } from 'react-intl';
import classes from './Event.module.scss';
import IconForSchedule from '@/atoms/IconForSchedule/IconForSchedule';
import CarIcon from '@/icons/car.svg';
import { getEventName } from '@/utils/helpers';
const Event = ({ item, past = false }) => {
    var _a;
    const intl = useIntl();
    return (React.createElement("div", { className: classes.event },
        React.createElement("div", { className: classes.date },
            dayjs(item === null || item === void 0 ? void 0 : item.start).format('DD.MM.YYYY, HH:mm'),
            " - ",
            dayjs(item === null || item === void 0 ? void 0 : item.end).format('DD.MM.YYYY, HH:mm')),
        React.createElement("div", { className: classes.eventHolder },
            React.createElement("div", { className: classes.left },
                React.createElement("div", { className: classes.eventBox },
                    React.createElement("div", { className: classes.icon },
                        React.createElement(IconForSchedule, { eventType: item.eventType, width: 24, height: 24 })),
                    React.createElement("div", { className: classes.eventInfo },
                        React.createElement("div", { className: classes.eventType }, getEventName(item.eventType, intl))))),
            React.createElement("div", { className: classes.right },
                React.createElement("div", { className: classes.carNumber },
                    React.createElement("div", { className: classes.carIcon },
                        React.createElement(CarIcon, null)),
                    React.createElement("div", { className: classes.carNumberValue }, (_a = item === null || item === void 0 ? void 0 : item.car) === null || _a === void 0 ? void 0 : _a.licensePlate))))));
};
export default Event;
