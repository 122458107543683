import { ApolloProvider } from '@apollo/client';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from '../App/App';
import LocaleProvider from '../LocaleProvider/LocaleProvider';
import client from '@/graphql/client';
import { store } from '@/store';
const Root = () => (React.createElement(Provider, { store: store },
    React.createElement(BrowserRouter, null,
        React.createElement(ApolloProvider, { client: client },
            React.createElement(LocaleProvider, null,
                React.createElement(App, null))))));
export default Root;
