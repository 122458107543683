export default {
    ad: 'Andorra',
    ae: 'Birlashgan Arab Amirliklari',
    af: 'Afgʻoniston',
    ag: 'Antigua va Barbuda',
    ai: 'Angilya',
    al: 'Albaniya',
    am: 'Armaniston',
    ao: 'Angola',
    aq: 'Antarktida',
    ar: 'Argentina',
    as: 'Amerika Samoasi',
    at: 'Avstriya',
    au: 'Avstraliya',
    aw: 'Aruba',
    ax: 'Aland orollari',
    az: 'Ozarbayjon',
    ba: 'Bosniya va Gertsegovina',
    bb: 'Barbados',
    bd: 'Bangladesh',
    be: 'Belgiya',
    bf: 'Burkina-Faso',
    bg: 'Bolgariya',
    bh: 'Bahrayn',
    bi: 'Burundi',
    bj: 'Benin',
    bl: 'Sen-Bartelemi',
    bm: 'Bermud orollari',
    bn: 'Bruney',
    bo: 'Boliviya',
    bq: 'Karib Niderlandlari',
    br: 'Braziliya',
    bs: 'Bagam orollari',
    bt: 'Butan',
    bv: 'Buve oroli',
    bw: 'Botsvana',
    by: 'Belarus',
    bz: 'Beliz',
    ca: 'Kanada',
    cc: 'Kokos orollari',
    cd: 'Kongo - Kinshasa',
    cf: 'Markaziy Afrika Respublikasi',
    cg: 'Kongo - Brazavil',
    ch: 'Shveytsariya',
    ci: 'Kot-d’Ivuar',
    ck: 'Kuk orollari',
    cl: 'Chili',
    cm: 'Kamerun',
    cn: 'Xitoy',
    co: 'Kolumbiya',
    cr: 'Kosta-Rika',
    cu: 'Kuba',
    cv: 'Kabo-Verde',
    cw: 'Kyurasao',
    cx: 'Rojdestvo oroli',
    cy: 'Kipr',
    cz: 'Chexiya',
    de: 'Germaniya',
    dj: 'Jibuti',
    dk: 'Daniya',
    dm: 'Dominika',
    do: 'Dominikan Respublikasi',
    dz: 'Jazoir',
    ec: 'Ekvador',
    ee: 'Estoniya',
    eg: 'Misr',
    eh: 'Gʻarbiy Sahroi Kabir',
    er: 'Eritreya',
    es: 'Ispaniya',
    et: 'Efiopiya',
    fi: 'Finlyandiya',
    fj: 'Fiji',
    fk: 'Folklend orollari',
    fm: 'Mikroneziya',
    fo: 'Farer orollari',
    fr: 'Fransiya',
    ga: 'Gabon',
    gb: 'Buyuk Britaniya',
    gd: 'Grenada',
    ge: 'Gruziya',
    gf: 'Fransuz Gvianasi',
    gg: 'Gernsi',
    gh: 'Gana',
    gi: 'Gibraltar',
    gl: 'Grenlandiya',
    gm: 'Gambiya',
    gn: 'Gvineya',
    gp: 'Gvadelupa',
    gq: 'Ekvatorial Gvineya',
    gr: 'Gretsiya',
    gs: 'Janubiy Jorjiya va Janubiy Sendvich orollari',
    gt: 'Gvatemala',
    gu: 'Guam',
    gw: 'Gvineya-Bisau',
    gy: 'Gayana',
    hk: 'Gonkong (Xitoy MMH)',
    hm: 'Heard va Makdonald orollari',
    hn: 'Gonduras',
    hr: 'Xorvatiya',
    ht: 'Gaiti',
    hu: 'Vengriya',
    id: 'Indoneziya',
    ie: 'Irlandiya',
    il: 'Isroil',
    im: 'Men oroli',
    in: 'Hindiston',
    io: 'Britaniya Hind okeani hududi',
    iq: 'Iroq',
    ir: 'Eron',
    is: 'Islandiya',
    it: 'Italiya',
    je: 'Jersi',
    jm: 'Yamayka',
    jo: 'Iordaniya',
    jp: 'Yaponiya',
    ke: 'Keniya',
    kg: 'Qirgʻiziston',
    kh: 'Kambodja',
    ki: 'Kiribati',
    km: 'Komor orollari',
    kn: 'Sent-Kits va Nevis',
    kp: 'Shimoliy Koreya',
    kr: 'Janubiy Koreya',
    kw: 'Kuvayt',
    ky: 'Kayman orollari',
    kz: 'Qozogʻiston',
    la: 'Laos',
    lb: 'Livan',
    lc: 'Sent-Lyusiya',
    li: 'Lixtenshteyn',
    lk: 'Shri-Lanka',
    lr: 'Liberiya',
    ls: 'Lesoto',
    lt: 'Litva',
    lu: 'Lyuksemburg',
    lv: 'Latviya',
    ly: 'Liviya',
    ma: 'Marokash',
    mc: 'Monako',
    md: 'Moldova',
    me: 'Chernogoriya',
    mf: 'Sen-Martin',
    mg: 'Madagaskar',
    mh: 'Marshall orollari',
    mk: 'Shimoliy Makedoniya',
    ml: 'Mali',
    mm: 'Myanma (Birma)',
    mn: 'Mongoliya',
    mo: 'Makao (Xitoy MMH)',
    mp: 'Shimoliy Mariana orollari',
    mq: 'Martinika',
    mr: 'Mavritaniya',
    ms: 'Montserrat',
    mt: 'Malta',
    mu: 'Mavrikiy',
    mv: 'Maldiv orollari',
    mw: 'Malavi',
    mx: 'Meksika',
    my: 'Malayziya',
    mz: 'Mozambik',
    na: 'Namibiya',
    nc: 'Yangi Kaledoniya',
    ne: 'Niger',
    nf: 'Norfolk oroli',
    ng: 'Nigeriya',
    ni: 'Nikaragua',
    nl: 'Niderlandiya',
    no: 'Norvegiya',
    np: 'Nepal',
    nr: 'Nauru',
    nu: 'Niue',
    nz: 'Yangi Zelandiya',
    om: 'Ummon',
    pa: 'Panama',
    pe: 'Peru',
    pf: 'Fransuz Polineziyasi',
    pg: 'Papua-Yangi Gvineya',
    ph: 'Filippin',
    pk: 'Pokiston',
    pl: 'Polsha',
    pm: 'Sen-Pyer va Mikelon',
    pn: 'Pitkern orollari',
    pr: 'Puerto-Riko',
    ps: 'Falastin hududlari',
    pt: 'Portugaliya',
    pw: 'Palau',
    py: 'Paragvay',
    qa: 'Qatar',
    re: 'Reyunon',
    ro: 'Ruminiya',
    rs: 'Serbiya',
    ru: 'Rossiya',
    rw: 'Ruanda',
    sa: 'Saudiya Arabistoni',
    sb: 'Solomon orollari',
    sc: 'Seyshel orollari',
    sd: 'Sudan',
    se: 'Shvetsiya',
    sg: 'Singapur',
    sh: 'Muqaddas Yelena oroli',
    si: 'Sloveniya',
    sj: 'Shpitsbergen va Yan-Maen',
    sk: 'Slovakiya',
    sl: 'Syerra-Leone',
    sm: 'San-Marino',
    sn: 'Senegal',
    so: 'Somali',
    sr: 'Surinam',
    ss: 'Janubiy Sudan',
    st: 'San-Tome va Prinsipi',
    sv: 'Salvador',
    sx: 'Sint-Marten',
    sy: 'Suriya',
    sz: 'Eswatini',
    tc: 'Turks va Kaykos orollari',
    td: 'Chad',
    tf: 'Fransuz Janubiy va Antarktika hududlari',
    tg: 'Togo',
    th: 'Tailand',
    tj: 'Tojikiston',
    tk: 'Tokelau',
    tl: 'Timor-Leste',
    tm: 'Turkmaniston',
    tn: 'Tunis',
    to: 'Tonga',
    tr: 'Turkiya',
    tt: 'Trinidad va Tobago',
    tv: 'Tuvalu',
    tw: 'Tayvan',
    tz: 'Tanzaniya',
    ua: 'Ukraina',
    ug: 'Uganda',
    um: 'AQSH yondosh orollari',
    us: 'AQSH',
    uy: 'Urugvay',
    uz: 'Oʻzbekiston',
    va: 'Vatikan',
    vc: 'Sent-Vinsent va Grenadin',
    ve: 'Venesuela',
    vg: 'Britaniya Virgin orollari',
    vi: 'AQSH Virgin orollari',
    vn: 'Vyetnam',
    vu: 'Vanuatu',
    wf: 'Uollis va Futuna',
    ws: 'Samoa',
    ye: 'Yaman',
    yt: 'Mayotta',
    za: 'Janubiy Afrika',
    zm: 'Zambiya',
    zw: 'Zimbabve'
};
