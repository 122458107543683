import { bindActionCreators } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { ActsActionCreators } from '../redux/acts/reducer';
import { AuthActionCreators } from '../redux/auth/reducer';
const ActionsCreators = Object.assign(Object.assign({}, AuthActionCreators), ActsActionCreators);
const useAppDispatch = () => {
    const dispatch = useDispatch();
    return bindActionCreators(ActionsCreators, dispatch);
};
export default useAppDispatch;
