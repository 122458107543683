import Dropdown from 'antd/es/dropdown/dropdown';
import React from 'react';
import { useIntl } from 'react-intl';
import classes from './Lang.module.scss';
import useAppSelector from '@/hooks/useAppSelector';
import ArrowDownIcon from '@/icons/arrows-down.svg';
import LangIcon from '@/icons/lang.svg';
import { languages } from '@/utils/constants';
const getLanguages = (handleChangeLang) => {
    const langList = languages.map((lang) => {
        return {
            key: lang.id,
            label: (React.createElement("div", { onClick: () => { handleChangeLang(lang.id); }, className: classes.langItem }, lang.label))
        };
    });
    return langList;
};
const Lang = ({ handleChangeLang }) => {
    const intl = useIntl();
    const { lang } = useAppSelector((state) => state.auth.user);
    const getCurrentLang = languages.find((langItem) => langItem.id === lang);
    const items = getLanguages(handleChangeLang);
    return (React.createElement("div", { className: classes.lang },
        React.createElement("div", { className: classes.langLeft },
            React.createElement("div", { className: classes.langTitle },
                React.createElement("div", { className: classes.langTitleIcon },
                    React.createElement(LangIcon, null)),
                intl.formatMessage({ id: 'common.lang', defaultMessage: 'Мова' }))),
        React.createElement("div", { className: classes.langRight },
            React.createElement(Dropdown, { menu: { items }, trigger: ['click'], placement: "topRight" },
                React.createElement("div", null,
                    React.createElement("div", { className: classes.langCurrent }, getCurrentLang === null || getCurrentLang === void 0 ? void 0 :
                        getCurrentLang.label,
                        React.createElement(ArrowDownIcon, { className: 'lang-arrow' })))))));
};
export default Lang;
