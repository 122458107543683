import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getAccessToken } from '@/utils/localStorage';
const httpLink = createHttpLink({
    uri: typeof process.env.API_HOST === 'string' && typeof process.env.API_PROTOCOL === 'string' ? `${process.env.API_PROTOCOL}://${process.env.API_HOST}` : '',
    credentials: process.env.SITE_HOST === 'localhost' ? 'same-origin' : 'include',
    fetch
});
const authLink = setContext((_, { headers }) => {
    const token = getAccessToken();
    return {
        headers: Object.assign(Object.assign({}, headers), token && { authorization: token })
    };
});
const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'network-only'
        }
    }
});
export default client;
