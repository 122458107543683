import { SnippetsOutlined } from '@ant-design/icons';
import { Alert } from 'antd';
import React from 'react';
import Preloader from '../Preloader/Preloader';
export const renderNotFoundContent = (loading, error, intl) => {
    if (loading)
        return React.createElement(Preloader, { inner: true });
    if (error) {
        return (React.createElement(Alert, { showIcon: true, type: "error", message: intl.formatMessage({ id: 'error.title', defaultMessage: 'Oops... что-то пошло не так!' }) }));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(SnippetsOutlined, { style: { marginRight: '5px' } }),
        intl.formatMessage({ id: 'error.dataNotFound', defaultMessage: 'Данные не найдены' })));
};
