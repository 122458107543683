import { createSlice } from '@reduxjs/toolkit';
import modifyField from '@/utils/redux/modifyField';
export const initialState = {
    modals: {
        rejectAct: {
            visibility: false,
            defaultValues: {}
        },
        cancelAct: {
            visibility: false,
            defaultValues: {}
        }
    }
};
export const actsSlice = createSlice({
    name: 'acts',
    initialState,
    reducers: {
        setActsPopup: {
            reducer(state, action) {
                const { modal, visibility, defaultValues } = action === null || action === void 0 ? void 0 : action.payload;
                state.modals = modifyField(state.modals, modal, {
                    visibility,
                    defaultValues: Object.assign(Object.assign({}, state.modals[modal].defaultValues), defaultValues)
                });
            },
            prepare(modal, visibility, defaultValues) {
                return { payload: { modal, visibility, defaultValues } };
            }
        }
    }
});
const { setActsPopup } = actsSlice.actions;
export const ActsActionCreators = { setActsPopup };
export default actsSlice.reducer;
