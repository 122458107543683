import React from 'react';
import { NUM_LOCALE } from '../../utils/constants';
import { getCurrencySymbol } from '../../utils/helpers';
const ValueWithCurrency = ({ value, minimumFractionDigits, maximumFractionDigits, withZero }) => {
    const currentAutopark = 'AED';
    const currencySymbol = getCurrencySymbol(currentAutopark);
    const formattedValue = value === null || value === void 0 ? void 0 : value.toLocaleString(NUM_LOCALE, Object.assign(Object.assign({}, minimumFractionDigits && { minimumFractionDigits: 2 }), maximumFractionDigits && { maximumFractionDigits: 0 }));
    if (!formattedValue && withZero) {
        return React.createElement(React.Fragment, null, "0");
    }
    if (!formattedValue) {
        return React.createElement(React.Fragment, null, "\u2014");
    }
    return React.createElement("div", { style: { display: 'inline-flex', alignItems: 'center' } }, currencySymbol ? (React.createElement(React.Fragment, null,
        React.createElement("p", { style: { margin: '0 4px 0 0' } }, currencySymbol),
        " ",
        formattedValue)) : formattedValue);
};
export default ValueWithCurrency;
