export default {
    ad: 'Andora',
    ae: 'Zjednoczone Emiraty Arabskie',
    af: 'Afganistan',
    ag: 'Antigua i Barbuda',
    ai: 'Anguilla',
    al: 'Albania',
    am: 'Armenia',
    ao: 'Angola',
    aq: 'Antarktyda',
    ar: 'Argentyna',
    as: 'Amerykańskie Samoa',
    at: 'Austria',
    au: 'Australia',
    aw: 'Aruba',
    ax: 'Wyspy Alandzkie',
    az: 'Azerbejdżan',
    ba: 'Bośnia i Hercegowina',
    bb: 'Barbados',
    bd: 'Bangladesz',
    be: 'Belgia',
    bf: 'Burkina Faso',
    bg: 'Bułgaria',
    bh: 'Bahrajn',
    bi: 'Burundi',
    bj: 'Benin',
    bl: 'Saint-Barthélemy',
    bm: 'Bermudy',
    bn: 'Brunei',
    bo: 'Boliwia',
    bq: 'Karibskie Niderlandy',
    br: 'Brazylia',
    bs: 'Bahamy',
    bt: 'Bhutan',
    bv: 'Wyspa Bouvet',
    bw: 'Botswana',
    by: 'Białoruś',
    bz: 'Belize',
    ca: 'Kanada',
    cc: 'Wyspy Kokosowe',
    cd: 'Kongo - Kinszasa',
    cf: 'Republika Środkowoafrykańska',
    cg: 'Kongo - Brazaville',
    ch: 'Szwajcaria',
    ci: 'Wybrzeże Kości Słoniowej',
    ck: 'Wyspy Cooka',
    cl: 'Chile',
    cm: 'Kamerun',
    cn: 'Chiny',
    co: 'Kolumbia',
    cr: 'Kostaryka',
    cu: 'Kuba',
    cv: 'Wyspy Zielonego Przylądka',
    cw: 'Curaçao',
    cx: 'Wyspa Bożego Narodzenia',
    cy: 'Cypr',
    cz: 'Czechy',
    de: 'Niemcy',
    dj: 'Dżibuti',
    dk: 'Dania',
    dm: 'Dominika',
    do: 'Dominikańska Republika',
    dz: 'Algieria',
    ec: 'Ekwador',
    ee: 'Estonia',
    eg: 'Egipt',
    eh: 'Zachodnia Sahara',
    er: 'Erytrea',
    es: 'Hiszpania',
    et: 'Etiopia',
    fi: 'Finlandia',
    fj: 'Fidżi',
    fk: 'Falklandy',
    fm: 'Mikronezja',
    fo: 'Wyspy Owcze',
    fr: 'Francja',
    ga: 'Gabon',
    gb: 'Wielka Brytania',
    gd: 'Grenada',
    ge: 'Gruzja',
    gf: 'Francuska Gujana',
    gg: 'Guernsey',
    gh: 'Ghana',
    gi: 'Gibraltar',
    gl: 'Grenlandia',
    gm: 'Gambia',
    gn: 'Gwinea',
    gp: 'Gwadelupa',
    gq: 'Gwinea Równikowa',
    gr: 'Grecja',
    gs: 'Georgia Południowa i Sandwichy Południowe',
    gt: 'Gwatemala',
    gu: 'Guam',
    gw: 'Gwinea-Bisau',
    gy: 'Gujana',
    hk: 'Hongkong (SAR Chin)',
    hm: 'Wyspy Heard i McDonalda',
    hn: 'Honduras',
    hr: 'Chorwacja',
    ht: 'Haiti',
    hu: 'Węgry',
    id: 'Indonezja',
    ie: 'Irlandia',
    il: 'Izrael',
    im: 'Wyspa Man',
    in: 'Indie',
    io: 'Brytyjskie Terytorium Oceanu Indyjskiego',
    iq: 'Irak',
    ir: 'Iran',
    is: 'Islandia',
    it: 'Włochy',
    je: 'Jersey',
    jm: 'Jamajka',
    jo: 'Jordania',
    jp: 'Japonia',
    ke: 'Kenia',
    kg: 'Kirgistan',
    kh: 'Kambodża',
    ki: 'Kiribati',
    km: 'Komory',
    kn: 'Saint Kitts i Nevis',
    kp: 'Korea Północna',
    kr: 'Korea Południowa',
    kw: 'Kuwejt',
    ky: 'Wyspy Kajmanowe',
    kz: 'Kazachstan',
    la: 'Laos',
    lb: 'Liban',
    lc: 'Saint Lucia',
    li: 'Liechtenstein',
    lk: 'Sri Lanka',
    lr: 'Liberia',
    ls: 'Lesotho',
    lt: 'Litwa',
    lu: 'Luksemburg',
    lv: 'Łotwa',
    ly: 'Libia',
    ma: 'Maroko',
    mc: 'Monako',
    md: 'Mołdawia',
    me: 'Czarnogóra',
    mf: 'Saint Martin',
    mg: 'Madagaskar',
    mh: 'Wyspy Marshalla',
    mk: 'Macedonia Północna',
    ml: 'Mali',
    mm: 'Mjanma (Birma)',
    mn: 'Mongolia',
    mo: 'Makau (SAR Chin)',
    mp: 'Północne Wyspy Marianskie',
    mq: 'Martynika',
    mr: 'Mauretania',
    ms: 'Montserrat',
    mt: 'Malta',
    mu: 'Mauritius',
    mv: 'Malediwy',
    mw: 'Malawi',
    mx: 'Meksyk',
    my: 'Malezja',
    mz: 'Mozambik',
    na: 'Namibia',
    nc: 'Nowa Kaledonia',
    ne: 'Niger',
    nf: 'Wyspa Norfolk',
    ng: 'Nigeria',
    ni: 'Nikaragua',
    nl: 'Niderlandy',
    no: 'Norwegia',
    np: 'Nepal',
    nr: 'Nauru',
    nu: 'Niue',
    nz: 'Nowa Zelandia',
    om: 'Oman',
    pa: 'Panama',
    pe: 'Peru',
    pf: 'Polinezja Francuska',
    pg: 'Papua-Nowa Gwinea',
    ph: 'Filipiny',
    pk: 'Pakistan',
    pl: 'Polska',
    pm: 'Saint Pierre i Miquelon',
    pn: 'Wyspy Pitcairn',
    pr: 'Puerto Rico',
    ps: 'Terytoria Palestyńskie',
    pt: 'Portugalia',
    pw: 'Palau',
    py: 'Paragwaj',
    qa: 'Katar',
    re: 'Réunion',
    ro: 'Rumunia',
    rs: 'Serbia',
    ru: 'Rosja',
    rw: 'Rwanda',
    sa: 'Arabia Saudyjska',
    sb: 'Wyspy Salomona',
    sc: 'Seszele',
    sd: 'Sudan',
    se: 'Szwecja',
    sg: 'Singapur',
    sh: 'Wyspa Świętej Heleny',
    si: 'Słowenia',
    sj: 'Svalbard i Jan Mayen',
    sk: 'Słowacja',
    sl: 'Sierra Leone',
    sm: 'San Marino',
    sn: 'Senegal',
    so: 'Somalia',
    sr: 'Surinam',
    ss: 'Sudan Południowy',
    st: 'Sao Tome i Principe',
    sv: 'Salwador',
    sx: 'Sint Maarten',
    sy: 'Syria',
    sz: 'Eswatini',
    tc: 'Wyspy Turks i Caicos',
    td: 'Czad',
    tf: 'Francuskie Terytoria Południowe i Antarktyczne',
    tg: 'Togo',
    th: 'Tajlandia',
    tj: 'Tadżykistan',
    tk: 'Tokelau',
    tl: 'Timor-Leste',
    tm: 'Turkmenistan',
    tn: 'Tunezja',
    to: 'Tonga',
    tr: 'Turcja',
    tt: 'Trynidad i Tobago',
    tv: 'Tuvalu',
    tw: 'Tajwan',
    tz: 'Tanzania',
    ua: 'Ukraina',
    ug: 'Uganda',
    um: 'Zewnętrzne Małe Wyspy USA',
    us: 'Stany Zjednoczone Ameryki',
    uy: 'Urugwaj',
    uz: 'Uzbekistan',
    va: 'Watykan',
    vc: 'Saint Vincent i Grenadyny',
    ve: 'Wenezuela',
    vg: 'Brytyjskie Wyspy Dziewicze',
    vi: 'Amerykańskie Wyspy Dziewicze',
    vn: 'Wietnam',
    vu: 'Vanuatu',
    wf: 'Wallis i Futuna',
    ws: 'Samoa',
    ye: 'Jemen',
    yt: 'Majotta',
    za: 'Republika Południowej Afryki',
    zm: 'Zambia',
    zw: 'Zimbabwe'
};
