import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import classes from './Countdown.module.scss';
const Countdown = () => {
    var _a, _b, _c;
    const [time, setTime] = useState(dayjs.duration(0));
    useEffect(() => {
        const interval = setInterval(() => {
            setTime((prevTime) => prevTime.add(1, 'second'));
        }, 1000);
        return () => { clearInterval(interval); };
    }, []);
    const formatTime = () => {
        const hours = time.hours().toString().padStart(2, '0');
        const minutes = time.minutes().toString().padStart(2, '0');
        const seconds = time.seconds().toString().padStart(2, '0');
        return [hours, minutes, seconds];
    };
    return (React.createElement("div", { className: classes.countdown },
        React.createElement("div", { className: classes.countdownItem }, (_a = formatTime()) === null || _a === void 0 ? void 0 : _a[0]),
        React.createElement("div", { className: classes.countdownItem }, (_b = formatTime()) === null || _b === void 0 ? void 0 : _b[1]),
        React.createElement("div", { className: classes.countdownItem }, (_c = formatTime()) === null || _c === void 0 ? void 0 : _c[2])));
};
export default Countdown;
